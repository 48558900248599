import * as React from 'react';
import {ChangeEvent, useEffect, useState} from 'react';
import Template from '../Template';
import {useActions} from "../../redux/actions";
import * as practiceActions from "../../redux/actions/practices";
import * as availabilityTrackingActions from "../../redux/actions/availabilityTracking";
import {
    Button,
    DropdownProps,
    Form,
    Grid,
    Icon,
    Input,
    InputOnChangeData,
    Table,
    Dropdown
} from "semantic-ui-react";
import moment from "moment";
import Sorting from '../Sorting';
import SelectedTimeOffsModal from "./SelectedTimeOffsModal";
import HelpMark from "../HelpMark";
import SelectedCDPDoctors from "./SelectedCDPDoctors";
import {DOCTOR_ID, udrPermissions} from '../../constants';
import * as DentistActions from "../../redux/actions/dentists";
import TrackingTable from "./TrackingTable";

interface ITimeOffMap {
    [day: number]: { [status: number]: number }
}

const AvailabilityTracking: React.FC<any> = () => {
    const initialState = {
        currentMonth: moment().startOf('M').add(1, 'w').toDate(),
        daysInMonth: [] as Date[],
        practices: [] as any[],
        practice: null as any,
        schedulesWithDoctors: [] as any[],
        selectedTimeOff: [] as any[],
        overrideDays: [] as any[],
        timeOffsMap: {} as ITimeOffMap,
        schedules: undefined as any,
        practiceWorkDaysMap: {} as any,
        brands: [] as any[],
        dentistsList: [] as any[],
        trackingData: [] as any
    };
    const [state, setStates] = useState(initialState);
    const [paramsState, setParamsStates] = useState({activeIndex: 0, search: ''});
    const practiceAction = useActions(practiceActions);
    const dentistActions = useActions(DentistActions);
    const availabilityTrackingAction = useActions(availabilityTrackingActions);
    const [orderBy, setOrderBy] = useState({})
    const [unsortedData, setUnsortedData] = useState<any>([])
    const [selectedBrands, setSelectedBrands] = useState<string[]>([])
    const [udaRole, setUdaRole] = useState([2,10])
    const {
        currentMonth,
        daysInMonth,
        practice,
        practices,
        practiceWorkDaysMap,
        brands,
        trackingData,
        dentistsList
    } = state;
    const {activeIndex, search} = paramsState;
    useEffect(() => {
        load();
    }, [udaRole]);

    const load = async () => {
        await loadBrands();
        await loadDates();

        const dentistsList = await dentistActions.getDoctorList();
        let dentistOptions: any[] = [];
        dentistsList && Object.keys(dentistsList).forEach((item: any) => {
            const dentist = dentistsList && dentistsList[item];
            dentistOptions.push({
                value: dentist.id, text: dentist.firstName + ' ' + dentist.lastName
            })
        });
        setStates((prevState) => ({...prevState, dentistsList: dentistOptions}));
    }

    const loadDates = async (currentMonth = new Date()) => {
         const udaOptions:any={}
         udaRole.forEach((val:any)=>udaOptions[val]=val)
        const params = {
            startDate: moment(currentMonth).startOf('month').format('Y-MM-01'),
            endDate: moment(currentMonth).endOf('month').format('Y-MM-DD'),
            udaRole:JSON.stringify({data:udaOptions})
        }
        const trackingData = await availabilityTrackingAction.getTrackingData(params) || [];
        const daysInMonth = [...new Array(moment(currentMonth).daysInMonth())]
            .map((value, i) => moment(currentMonth).date(i + 1).toDate());
        let practices = loadPractices(trackingData.flatMap(a => a.practice));
        const practiceWorkDaysMap = practices.reduce((practiceMap, practice) => {
            let totalDays = 0;
            const drPractice = +(practice.udaPracticeInfo && practice.udaPracticeInfo.drPractice) || 1;
            const practiceTrackingData = trackingData.find(f => f.practice.id == practice.id) || {}
            const mapPerDay = practiceTrackingData.mapPerDay || {};
            const data = daysInMonth.reduce((dayMap, day) => {
                const i = day.getDate();
                dayMap[i] = !mapPerDay[i] || mapPerDay[i].closed ? 0 : +drPractice;
                totalDays += dayMap[i];
                return dayMap;
            }, {} as any);
            practiceMap[practice.id] = {totalDays, data};
            return practiceMap;
        }, {});

        const stateObj = {practiceWorkDaysMap, daysInMonth, currentMonth, trackingData};
        setStates((prevState) => ({...prevState, ...stateObj}));
        return stateObj;
    }

    const onPracticeChange = async (e, {value}: DropdownProps) => {
        const practiceId = (value && +value) || 0;
        if (activeIndex == practiceId || practiceId == 0) {
            setParamsStates((prevState) => ({...prevState, activeIndex: 0}));
            return;
        }

        const practice = state.practices.find(f => f.id == practiceId);
        setStates((prevState) => ({...prevState, practice}));
        setParamsStates((prevState) => ({...prevState, activeIndex: practiceId}));
    }

    const addMonth = async (month: number) => {
        const date = moment(currentMonth).add(month, 'M').startOf('M').add(1, 'w').toDate();
        await loadDates(date);
    }

    const loadPractices = (practices: any[]) => {
        const practice = practices[0];
        setStates((prevState) => ({...prevState, practices, practice}));
        setUnsortedData(practices)
        return practices;
    }

    const loadBrands = async () => {
        const brands: any = await practiceAction.getBrands();
        brands.unshift('All');
        setStates((prevState) => ({...prevState, brands}))
    }

    const onPracticeSearch = (event: ChangeEvent, data: InputOnChangeData) => {
        setParamsStates((prevState) => ({...prevState, search: data.value, activeIndex: 0}));
    };

    const getDataForPractice = (practiceObj) => {
        return trackingData.find(f => f.practice.id == practiceObj.id) || {};
    }

    const handleSortClick = (obj) => {
        const sortConfigs = {
            days: (a) => a.overrides,
            drPractice: (a) => new Array(practiceWorkDaysMap[a.practice.id] && practiceWorkDaysMap[a.practice.id].totalDays),
            sum99: (a) => a.timeOffs,
            sum1: (a) => a.timeOffsByStatus[1],
            sum2: (a) => a.timeOffsByStatus[2],
            dr62Schedule: (a) => a.schedulePerDr[DOCTOR_ID.Dr_EVANS],
            dr58Schedule: (a) => a.schedulePerDr[DOCTOR_ID.DR_COPELAND],
            daysFilled: (a) => a.productions,
            scheduledSubs: (a) => {
                return a.timeOffs.map(a => a.substitutes)
                    .flat()
                    .filter((value, index, self) => index === self.findIndex((t) => t.id === value.id))
                    .filter(b => {
                        let dateOffData = new Date(b.timeOffDate).getMonth() + "-" + new Date(b.timeOffDate).getFullYear()
                        let dayInMonth = new Date(daysInMonth[0]).getMonth() + "-" + new Date(daysInMonth[0]).getFullYear()
                        let timeOffDay = a.mapPerDay.filter(a => a.day == new Date(b.timeOffDate).getDate() && a.timeOffs.length > 0).length > 0
                        return b.substituteBy > 0 && dateOffData === dayInMonth && timeOffDay
                    });
            },
            cdpDoctors: (a) => a.homeLocationMap.NO
        }
        const sortByFunc = sortConfigs[obj[0]];
        if (!sortByFunc) return;
        const direction = obj[1] === 'ASC'
            ? (a, b) => (sortByFunc(a) || []).length - (sortByFunc(b) || []).length
            : (a, b) => (sortByFunc(b) || []).length - (sortByFunc(a) || []).length;
        const practices = unsortedData.sort((a, b) => direction(getDataForPractice(a), getDataForPractice(b)));
        setStates((prevState) => ({...prevState, practices}))
        setOrderBy({[obj[0]]: obj[1]});
    }

    const removeSort = (orderByKey) => {
        const tempOrderBy = {...orderBy};
        delete tempOrderBy[orderByKey]
        setOrderBy(tempOrderBy);
        setStates((prevState) => ({...prevState, practices: unsortedData}))
    }

    const selectTimeOffs = (timeOffs) => {
        setStates((prevState) => ({...prevState, selectedTimeOff: timeOffs}));
    }

    const selectSchedules = (schedules) => {
        setStates((prevState) => ({...prevState, schedules}));
    }
    const brandOptions = (brands || []).map((brand, key) => ({value: brand, text: brand, key: `brand_${key}`}));
    const getFilteredPractices = () => {
        const practiceSearch = (f) => f.practice.toLowerCase().includes(search.toLowerCase());
        const brandSearch = (f) => selectedBrands.length < 1
            || selectedBrands.includes("All")
            || selectedBrands.includes(f.udaPracticeInfo && f.udaPracticeInfo.brand);
        const trackDataSearch = (f) => (trackingData || []).find(g => g.practice && g.practice.id == f.id);
        return (practices || []).filter(f => practiceSearch(f) && brandSearch(f) && trackDataSearch(f));
    }

    return (
        <Template activeLink="tracking">
            <Grid className="p-1 pb-0">
                <Grid.Row>
                    <Grid.Column width={6}>
                        <h1 className="float-left">Availability Tracking <HelpMark pageId='1'/></h1>
                    </Grid.Column>
                    <Grid.Column width={10}>
                        <Form>
                            <Form.Group className="mb0 float-right">
                                <Form.Field>
                                    <Button.Group>
                                        <Button primary={true} icon='left chevron'
                                                onClick={() => addMonth(-1)}/>
                                        <Button content={<b>{moment(currentMonth).format('MMMM YYYY')}</b>}/>
                                        <Button primary={true} icon='right chevron'
                                                onClick={() => addMonth(1)}/>
                                    </Button.Group>
                                </Form.Field>
                                <Form.Field>
                                    <Input placeholder='Search Practice..' onChange={onPracticeSearch}/>
                                </Form.Field>
                                <Form.Field>
                                    <Dropdown
                                        options={brandOptions}
                                        selection
                                        multiple
                                        style={{zIndex: 901}}
                                        placeholder='Brands'
                                        onChange={(e, data) => {
                                            setSelectedBrands((data.value as any))
                                        }}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Dropdown
                                        options={udrPermissions}
                                        selection
                                        style={{zIndex: 901}}
                                        value={udaRole}
                                        multiple
                                        placeholder='UDA Role'
                                        onChange={(_e, data) => {
                                            setUdaRole(data.value as any)
                                        }}
                                    />
                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <SelectedTimeOffsModal timeOffs={state.selectedTimeOff}
                                   close={() => setStates((prevState) => ({...prevState, selectedTimeOff: []}))}/>
            <SelectedCDPDoctors selected={state.schedules}
                                close={() => setStates((prevState) => ({...prevState, schedules: undefined}))}/>
            {
                practice &&
                <Table size="small" striped={true} compact={true} celled={true}
                       className="table-responsive sticky-header">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={2}/>
                            <Table.HeaderCell>
                                Dates
                                <Sorting
                                    handleClick={handleSortClick}
                                    order={orderBy['dates']}
                                    removeSort={() => removeSort('dates')}
                                    id={`dates`}
                                    orderBy={orderBy['dates'] ? 'dates' : ""}

                                />
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Override
                                <Sorting
                                    order={orderBy['days']}
                                    handleClick={handleSortClick}
                                    removeSort={removeSort}
                                    id={`days`}
                                    orderBy={orderBy['days'] ? 'days' : ''}

                                />
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Dr Practice
                                <Sorting
                                    order={orderBy['drPractice']}
                                    handleClick={handleSortClick}
                                    removeSort={removeSort}
                                    id={`drPractice`}
                                    ordeBy={orderBy['drPractice'] ? 'drPractice' : ''}
                                />
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Scheduled Subs
                                <Sorting
                                    order={orderBy['scheduledSubs']}
                                    handleClick={handleSortClick}
                                    removeSort={removeSort}
                                    id={`scheduledSubs`}
                                    orderBy={orderBy['scheduledSubs'] ? 'scheduledSubs' : ''}

                                />
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Days Filled
                                <Sorting
                                    order={orderBy['daysFilled']}
                                    handleClick={handleSortClick}
                                    removeSort={removeSort}
                                    id={`daysFilled`}
                                    orderBy={orderBy['daysFilled'] ? 'daysFilled' : ''}

                                />
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                TimeOffs
                                <Sorting
                                    order={orderBy['sum99']}
                                    handleClick={handleSortClick}
                                    removeSort={removeSort}
                                    id={`sum99`}
                                    orderBy={orderBy['sum99'] ? 'sum99' : ''}

                                />
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                TimeOffs Approved
                                <Sorting
                                    order={orderBy['sum1']}
                                    handleClick={handleSortClick}
                                    removeSort={removeSort}
                                    id={`sum1`}
                                    orderBy={orderBy['sum1'] ? 'sum1' : ''}
                                />
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                TimeOffs Denied
                                <Sorting
                                    order={orderBy['sum2']}
                                    handleClick={handleSortClick}
                                    removeSort={removeSort}
                                    id={`sum2`}
                                    orderBy={orderBy['sum2'] ? 'sum2' : ''}

                                />
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <Icon name="redo"/> Dr. Evans
                                <Sorting
                                    handleClick={handleSortClick}
                                    order={orderBy["dr62Schedule"]}
                                    removeSort={removeSort}
                                    id={`dr62Schedule`}
                                    orderBy={orderBy["dr62Schedule"] ? 'dr62Schedule' : ''}

                                />
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <Icon name="redo"/> Dr. Copeland
                                <Sorting
                                    order={orderBy["dr58Schedule"]}
                                    handleClick={handleSortClick}
                                    removeSort={removeSort}
                                    id={`dr58Schedule`}
                                    orderBy={orderBy["dr58Schedule"] ? 'dr58Schedule' : ''}
                                />
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <Icon name="redo"/> CDP Doctor
                                <Sorting
                                    order={orderBy["cdpDoctors"]}
                                    handleClick={handleSortClick}
                                    removeSort={removeSort}
                                    id={`cdpDoctors`}
                                    orderBy={orderBy["cdpDoctors"] ? 'cdpDoctors' : ''}
                                />
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <TrackingTable
                        trackingData={trackingData}
                        practices={getFilteredPractices()}
                        activeIndex={activeIndex}
                        currentMonth={currentMonth}
                        dentistsList={dentistsList}
                        onPracticeChange={onPracticeChange}
                        selectSchedules={selectSchedules}
                        selectTimeOffs={selectTimeOffs}
                        daysInMonth={daysInMonth}/>
                </Table>
            }
            <br/>
        </Template>
    );

}
export default AvailabilityTracking;
