import {ActionType} from "../../model/actionType"
import {errorHandler} from "./auth"

export const getDoctorList = () => async (dispatch:Function,getState:Function,api:any)=>{
    const url = `dentists`;
    dispatch({type:ActionType.LOADER,payload:true})
    try {
        const resp = await api.get(url);
        dispatch({ type: ActionType.LOAD_DOCTORS, payload: { list:  resp }  });
        dispatch({type:ActionType.LOADER,payload:false})
        return resp;

    } catch(err) {
        dispatch({type:ActionType.LOADER,payload:false})
        return dispatch(errorHandler(err))
    }
}

