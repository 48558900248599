import React ,{useState}from "react"
import {Menu, Dropdown,Image} from 'semantic-ui-react';
import FeedbackModal from "./FeedbackModal"
import {Account} from "../model/user"
import moment from "moment";
import {useActions} from "../redux/actions"
import * as AppActions from "../redux/actions/app"
interface Props{
    user:Account
    logout:any;
    listenerFromParent:any,
    handleClick:Function,
    mycdp_uri:string
}

const Header:React.FC<Props>=(props)=>{
    const [feedbackModal,setFeedbackPageModal]=useState(false)
    const [activeItem,setActiveItem]=useState("")
    const [feedbackForm,setFeedbackForm]=useState({} as any)
    const appActions=useActions(AppActions)
    const {user, logout, listenerFromParent , mycdp_uri} = props;
    const openFeedback=()=>{
        setFeedbackPageModal(true)
    }
    const hideFeedbackModal=()=>{
        setFeedbackPageModal(false)
    }
    const onClickFeedbackPage = () => {
        if (!feedbackForm.subject || !feedbackForm.message) {
            alert('Please enter subject and message');
            return;
        }
        const feedback = {
            subject: feedbackForm.subject,
            message: feedbackForm.message,
            firstName: user.firstname,
            lastName: user.lastname,
            email: user.email,
            practice: feedbackForm.practice,
            file: feedbackForm.file,
            dateTime: moment().format('MM/DD/YYYY hh:mm A')
        };
        appActions.sendFeedback(feedback)
        hideFeedbackModal();
    }

    const onChangeFeedback = (formData: any) => {
        setFeedbackForm({...feedbackForm, ...formData});
    };


    let isAdmin;
    let image;
    if(user){
        isAdmin=user.isAdmin || false
        image=<span><Image avatar className='mr-1' src={user.profilePicture}/> <span className="userName">{user.firstname} {user.lastname}</span></span>
    }
    const helpUrl = mycdp_uri ? mycdp_uri.replace('?token', `admin/setup/manager-help?token`) : '#';
    return (

        <Menu secondary className='mb-0 mt-0'>
            <a onClick={() => openFeedback()} className="btnFeedback">
                <small>FEEDBACK</small>
            </a>
            { feedbackModal &&
            <FeedbackModal hideFeedbackModal={hideFeedbackModal} onChangeFeedback={onChangeFeedback} onClickFeedbackPage={onClickFeedbackPage}/>
            }
            <Menu.Item className='ml-0 mr-0 mr-lg-4 float-left float-lg-none toggle inverted' icon='content'
                       active={activeItem === 'home'}
                       onClick={listenerFromParent}/>
            <div className="clickable" onClick={(e)=>{props.handleClick(e)}}>
                <img src="/images/logoSmall.png" className="logoSmall" alt="Logo"/>
            </div>
            <Menu.Menu position='right' className='float-right float-lg-none'>
                <Dropdown className="user" trigger={image} icon={null}/>
                <Dropdown className='setting inverted item mr-0' icon='ellipsis vertical'>
                    <Dropdown.Menu>
                        <Dropdown.Item text='Logout' onClick={logout}/>
                        <Dropdown.Item text='Feedback' onClick={openFeedback} />
                        {isAdmin ? <Dropdown.Item>
                      <a style={{color:'rgba(0, 0, 0, 0.87)'}} href={helpUrl} target='_blank' rel="noreferrer">Manage Help</a>
                    </Dropdown.Item> : ""}
                    </Dropdown.Menu>
                </Dropdown>
            </Menu.Menu>
        </Menu>
    )
}

export default Header
