import {ActionType} from "../../model/actionType";
import {errorHandler} from "./auth";

export const getSchedules = (params:any) => async (dispatch:Function,getState:Function,api:any)=>{
    if(params) {
        params = '/'+JSON.stringify(params);
    }
    const url = `schedule${params}`;
    dispatch({type:ActionType.LOADER,payload:true})
    try {
        const resp = await api.get(url);
        dispatch({ type: ActionType.LOAD_SCHEDULE, payload: { regions:  resp }  });
        dispatch({type:ActionType.LOADER,payload:false})
        return resp;

    } catch(err) {
        dispatch({type:ActionType.LOADER,payload:false})
        return dispatch(errorHandler(err))
    }
}
export const getDoctorSchedules = (params:any) => async (dispatch:Function,getState:Function,api:any)=>{
    const query = '?'+ Object.keys(params || {}).map(key => `${key}=${params[key]}`).join('&');
    const url = `schedule/doctorSchedules${query}`;

    dispatch({type:ActionType.LOADER,payload:true})
    try {
        const resp = await api.get(url);
        dispatch({ type: ActionType.LOAD_SCHEDULE, payload: { regions:  resp }  });
        dispatch({type:ActionType.LOADER,payload:false})
        return resp;

    } catch(err) {
        dispatch({type:ActionType.LOADER,payload:false})
        return dispatch(errorHandler(err))
    }
}