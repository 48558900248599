import * as React from 'react';
import {Header, Label, Modal, Table} from "semantic-ui-react";

const SelectedTimeOffsModal: React.FC<any> = ({timeOffs, close}) => {
    const colorMap = {1: 'green', 2: 'red', 0: 'grey'};
    const statusMap = {1: 'Approved', 2: 'Declined', 0: 'Pending'};
    const isSelected = timeOffs && timeOffs.length;
    if(!isSelected) return <span/>

    return (
        <Modal
            closeIcon
            open={timeOffs && timeOffs.length}
            onClose={() => close()}
        >
            <Header icon='clock' content='Time Off Requests' />
            <Modal.Content>
                <Table striped={true}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell content="Start"/>
                            <Table.HeaderCell content="End"/>
                            <Table.HeaderCell content="Days"/>
                            <Table.HeaderCell content="Practice"/>
                            <Table.HeaderCell content="Doctor"/>
                            <Table.HeaderCell content="Reason"/>
                            <Table.HeaderCell content="Status"/>
                            <Table.HeaderCell content="Approved By"/>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            timeOffs && timeOffs.length && timeOffs.map(data =>
                                <Table.Row>
                                    <Table.Cell content={data.startDate}/>
                                    <Table.Cell content={data.endDate}/>
                                    <Table.Cell content={<Label color={colorMap[data.status]} content={data.days}/>}/>
                                    <Table.Cell content={data.practice}/>
                                    <Table.Cell content={data.doctorName}/>
                                    <Table.Cell content={data.reason}/>
                                    <Table.Cell content={<Label color={colorMap[data.status]} content={statusMap[data.status]}/>}/>
                                    <Table.Cell content={data.approver}/>
                                </Table.Row>)
                        }
                    </Table.Body>
                </Table>
            </Modal.Content>
        </Modal>
    )

}
export default SelectedTimeOffsModal;
