import {combineReducers} from "redux"
import * as authReducer from "./user";
import * as appReducer from "./app";
import * as alertReducer from "./alert";
import * as timeOffReducer from "./timeOffRequests";
import * as reportsReducer from "./reports";
import * as practicesReducer from "./practices";
import * as dentistsReducer from "./dentists";
import * as dentistDaysReducer from "./dentistDays";
export default ()=>
   combineReducers({
       ...authReducer,
       ...appReducer,
       ...alertReducer,
       ...timeOffReducer,
       ...practicesReducer,
       ...dentistsReducer,
       ...reportsReducer,
       ...dentistDaysReducer
   })