import React, { useState, useEffect } from "react"
import { useActions } from "../redux/actions";
import * as Actions from "../redux/actions/auth";
import { Redirect } from "react-router";
import { Button, Form, Grid } from "semantic-ui-react";
import { Alert, RootState } from "../model";
import { connect } from "react-redux";
import { Account } from "../model/user";

interface Props {
    user: Account
    alert: Alert
}

const Login: React.FC<any> = (props: Props) => {
    const initialState = {
        username: '',
        password: '',
    };
    const [state, setState] = useState(initialState);
    const { user, alert } = props;
    const authActions = useActions(Actions);
    const onChange = (e: any) => {
        setState({ ...state, [e.target.name]: e.target.value });
    }

    const doLogin = (event: any) => new Promise((resolve, reject) => {
        event.preventDefault();
        const { username, password } = state;
        const obj = { loginWithForm: true, username: username, password: password };
        authActions.LogInWithForm(obj);
    });

    if (user?.id) return <Redirect to={'/time-off-requests'} />;

    return (

        <div className="login">
            <div className="contentCenter container-fluid">
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={8} className='sectionLeft'> </Grid.Column>
                        <Grid.Column width={8}>
                            <div className="row">
                                <div className="col-12">
                                    <img src="/images/logoMain.png" className="align-self-center loginLogo" alt="Logo"></img>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column floated='right' className="customGrid" computer={14} tablet={16} mobile={16}>
                                                <Grid>
                                                    <Grid.Row centered>
                                                        <Grid.Column computer={8} tablet={8} mobile={15}>
                                                            <Form className="mt10" onSubmit={doLogin}>
                                                                <Form.Field>
                                                                    <label>USERNAME</label>
                                                                    <input className='form-control'
                                                                        type="text" name="username"
                                                                        value={state.username || ""}
                                                                        onChange={onChange} required />
                                                                </Form.Field>
                                                                <Form.Field>
                                                                    <label>PASSWORD</label>
                                                                    <input className='form-control'
                                                                        type="password" name="password"
                                                                        value={state.password || ""}
                                                                        onChange={onChange} required />
                                                                </Form.Field>

                                                                <Button primary size='mini'>Login</Button>
                                                            </Form>
                                                            {alert['message'] && <p className={'red mt30'}>{alert['message']}</p>}
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Grid.Column>
                                        </Grid.Row>

                                    </Grid>


                                </div>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </div>
    );
}

function mapStateToProps(state: RootState) {
    return {
        user: state.auth.user,
        alert: state.alert
    };
}

export default connect(mapStateToProps)(Login);
