import moment from "moment";

export const API_URL: string =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5007"
    : "https://apischeduler.cdp.dental";

export interface OptionsType {
  key: string;
  value: string;
  text: string;
}

export const memberResult = [
  "Appointment Made",
  "Contact Made Not Interested",
  "Contact Made Call Back",
  "Left Message",
  "Phone number issues",
  "DUPLICATE RECORD",
  "Do Not Contact",
  "No Answer",
  "Other",
  "Need Review"
];

export const membersResultFilters = memberResult.map((item) => {
  return {
    key: item,
    value: item,
    text: item,
  };
});
export const brands = [
  { text: "All", value: "All", key: "All" },
  { text: "SMD", value: "SMD", key: "SMD" },
  { text: "FSD", value: "FSD", key: "FSD" },
  { text: "OTD", value: "OTD", key: "OTD" },
  { text: "3CD", value: "3CD", key: "3CD" },
  { text: "SYN", value: "SYN", key: "SYN" }
];

export enum DOCTOR_ID {
  DR_COPELAND = 58,
  Dr_EVANS = 62
};

export const allowDeleteUserId = [163, 1710, 791, 1189, 1180];
export const udrPermissions = [
  { text: "All", value: 0, key: "All" },
  { text: "Admin", value: 1, key: "Admin" },
  { text: "Dentist", value: 2, key: "Dentist" },
  { text: "Orthodontics", value: 3, key: "Orthodontics" },
  { text: "Oral Surgeon", value: 4, key: "Oral Surgeon" },
  { text: "Traveling Team", value: 5, key: "Traveling Team" },
  { text: "Credentialing", value: 6, key: "Credentialing" },
  { text: "View Only", value: 7, key: "View Only" },
  { text: "Hygienists", value: 9,key:"Hygienists"},
  { text: "Regional Manager", value: 8,key:"Regional Manager" },
  { text: "Provider Light", value: 10,key:"Provider Light"},
  { text: "SOFD", value: 11,key:"SOFD" }
];

export const timeOffStatusOptions = [
  { key: -1, color: '', title: "View All" },
  { key: 0, color: "#EECEAF", title: "New" },
  { key: 1, color: "#61DDBC", title: "Approved" },
  { key: 2, color: "#ff7674", title: "Disapproved" },
  { key: 3, color: "#FBD277", title: "Pending" },
]
export const timeOffViewOptions = [
  { value: "both", text: "View Both" },
  { value: "providers", text: "Providers" },
  { value: "practices", text: "Practices" },
]

export const viewOptions = [
  { value: "scheduler", text: "Scheduler" },
  { value: "calendar", text: "Calendar" },
]

export const viewBannerOptions = [
  { value: 0, text: "Hide Banner" },
  { value: 1, text: "Show Banner" },
]

export const timeOffStatusColors  = [
  '#EECEAF', '#61DDBC' , '#ff7674', '#FBD277'
]

export const timeOffStatusLocationClr  = [
  '#CE6429', '#35BA9B' , '#d63032', '#CE6429'
]

export const monthsArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const dentistTypesOptions = [
    { key: 1, value: false, text: 'Dentist Type' },
    { key: 2, value: 'General', text: 'General' },
    { key: 3, value: 'Ortho', text: 'Ortho' },
    { key: 4, value: 'Oral', text: 'Oral' },
    { key: 9, value: 'Hygienists', text: 'Hygienists' }
  ]

export const approvalOptions = [
  { value: 0, text: "Pending" },
  { value: 1, text: "Approve" },
  { value: 2, text: "Disapprove" }
]

export const defaultHomePage = 'time-off-requests'

export const googleClientId = "534451231648-d2a526h66llkn1e781gv6fgjvofsph2c.apps.googleusercontent.com"

export const HelpPages: any = {
  1: { name: "" },
}

export const PROVIDER_FILL_HEADERS = [
  {name: 'Expected Days', key: 'expected'},
  {name: 'Workable Days', key: 'workable'},
  {name: '% Filled', key: 'total'},
  {name: 'Total Filled', key: 'ratio'}
]

const startTime = moment(0).utc().add(-15, 'minutes');
const startTimes = [...new Array(24 * 4)].map(() => {
  startTime.add(15, 'minutes');
  return {text: startTime.format('hh:mm A'), value: startTime.valueOf() + ''}
})
export const PRACTICE_TIMES = [
  { value: '0', text: "CLOSED" },
    ...startTimes
];

export const DR_PRACTICE_OPTIONS = [
  { text: '1 Dr', value: '1', key: '1' },
  { text: '2 Dr', value: '2', key: '2' },
  { text: '3 Dr', value: '3', key: '3' },
  { text: 'Denovo', value: '-1', key: '-1' }
];

export const OPEN_WEEKS_ARRAY = [
  { value: 'all', text: "ALL" },
  { value: 'odd', text: "ODD WEEKS OF YEAR" },
  { value: 'even', text: "EVEN WEEKS OF YEAR" },
  { value: 'custom', text: "CUSTOM" }
];

export const RECURRENCE_OPTIONS = [
  {text: 'None', value: 0},
  ...[1, 2, 3, 4].map(i => ({value: `${i}_week`, text: `Every ${i} week(s)`})),
  ...[1].map(i => ({value: `${i}_month`, text: `Every ${i} month(s)`})),
]
