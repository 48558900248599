
    export const getUsageData = (userId?:string , role ='Dentist') => async (dispatch: Function, getState: Function, api: any) => {
        if(!userId){
          userId ='All';
        }
        if(role) {
          role = '/'+role;
        } else {
          role = '/All';
        }
        try {
          const res = await api.get(`usage/getUsage/${userId}${role}` , '');
         if(res){
             return res;
         }
        } catch (err) {
          return err;
        }
      };

    export const saveUdaUsageReport = (module:string, loginVia:string, deviceOS:string, user?:any) => async (dispatch:Function, getState: Function, api:any) =>{
        if(!user || !user.id) {
          setTimeout(function() { saveUdaUsageReport(module, loginVia, deviceOS, user) }, 1000);
          return false;
        }
        let udaRole = ['Admin'];
        if (user && user.UdrUsersPermissions  && user.UdrUsersPermissions.length) {
          user.UdrUsersPermissions.forEach((item:any)=>{
            udaRole.push(item.UdrPermission.role);
          });
        }
        try {
            const data = {
                userId: user.id,
                firstName: user.firstname,
                lastName: user.lastname,
                udaRole: udaRole && udaRole.join(","),
                loginVia:loginVia,
                module: module,
                deviceOS: deviceOS
            };
            api.post('/usage/saveUdaUsages', data);
          return true;
        } catch (err) {
         return err;
        }
      };
    
      export const saveUsage = (module:string, loginVia:string, deviceOS:string,  user?:any) => async (dispatch:Function) => {
        if(!user || !user.id) {
          setTimeout(function() { saveUdaUsageReport(module, loginVia, deviceOS, user) }, 1000);
        } else {
          saveUdaUsageReport(module, loginVia, deviceOS, user);
        }
    
      };
