import React, { useState,useEffect} from "react"
import CookieModal from "./CookieModal"
import {useActions} from "../redux/actions";
import * as Actions from "../redux/actions/auth";
import {Redirect} from "react-router";
import {Grid} from "semantic-ui-react";
import {defaultHomePage} from "../constants";
import {useGoogleLogin} from "@react-oauth/google";

const SignIn:React.FC<any>=(props)=> {
    const initialState: any = {
        cookieModal: false,
        isAuthenticated: false
    }
    const [state, setState] = useState(initialState);
    const authActions = useActions(Actions)

    useEffect(() => {
        const queryParams: any = new URLSearchParams(window.location.search);
        const accessToken = queryParams.get("token");
        if (accessToken && accessToken.length > 0) {
            authActions.cdpLogin(accessToken)
                .then((res: any) => {
                    if (res.user && res.user.id) {
                        setState({...state, isAuthenticated: true})
                    }
                })
        }
    }, [])

    const doLogin = async (data: any) => {
        const profile = await authActions.getGoogleUserDetails(data.access_token);
        if (!profile) {
            setState({...state, cookieModal: true});
            return;
        }
        let profileObj = {
            email: profile?.email,
            profilePicture: profile?.picture,
            firstName: profile?.givenName,
        };
        await authActions.LogIn(profileObj);
    };

    const onFailure = (response: any) => new Promise((resolve, reject) => {
        if (response && response.error === 'idpiframe_initialization_failed') {
            setState({...state, cookieModal: true});
        }
    });


    const login = useGoogleLogin({
        onSuccess: (codeResponse) => {
            doLogin(codeResponse).then();
        },
        onError: () => {
        },
    });

    const closeCookieModal = () => {
        setState({...state, cookieModal: false});
    }
    return (

        <div className="login">
            {state.isAuthenticated && <Redirect to={`/${defaultHomePage}`}/>}
            <div className="contentCenter container-fluid">
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={8} className='sectionLeft'>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <div className="row">
                                <div className="col-12">
                                    <img src="/images/logoMain.png" className="align-self-center loginLogo"
                                         alt="Logo"></img>
                                    <h1 className="ui header inverted mt-0 mb-4">SIGN IN</h1>
                                    <button
                                        onClick={() => login()}
                                        type="button"
                                        className="login-with-google-btns"
                                    >
                                        Sign in with Google
                                    </button>
                                    {state.cookieModal && <CookieModal handleClose={closeCookieModal}/>}
                                </div>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </div>
    );
}
export default SignIn