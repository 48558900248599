import React, {useState} from "react"
import {Button, Dropdown, Form, Grid, Icon, Input, Label, Modal, Radio, TextArea} from "semantic-ui-react";
import moment from "moment";
import {RouteComponentProps} from "react-router-dom";
import * as TimeOffRequestsActions from "../redux/actions/timeOffRequests";
import {useActions} from "../redux/actions";
import DentistListModal from "./DentistListModal";
import {approvalOptions} from "../constants";
const dateOnlyFormat = 'YYYY-MM-DD';

interface Props extends  RouteComponentProps<void> {
  onModalClose: Function,
  loadData : Function,
  eventDetails: any,
  dentistOptions : any,
  auth : any,
  dentistsList : any
}

const TimeOffPopup:React.FC<any>=(props: Props)=>{
  const initialState:any = {
    doctorsListModal: false,
    timeOffStartDate: false,
    timeOffEndDate: false,
    availableDentists: false,
    unscheduledDoctors: false,
    selectedDentist: false,
  }
  const [state, setState] = useState(initialState);
  const [loader, setLoader] = useState(false);
  const substitutes:any = props.eventDetails && props.eventDetails.eventData && props.eventDetails.eventData.substitutes;
  const substituteByDate: any = {};
  substitutes.forEach((item: any) => {
    const timeOffDate = moment(item.timeOffDate).utc().format(dateOnlyFormat)
    substituteByDate[timeOffDate] = item.substituteBy;
  })


  const updateEventData:any = {
    adminNotes : props.eventDetails && props.eventDetails.eventData && props.eventDetails.eventData.adminNotes,
    approvedBy : false,
    status : props.eventDetails && props.eventDetails.eventData && props.eventDetails.eventData.status,
    substituteArr : substituteByDate
  }
  const [updateEvent, setUpdateEvent] = useState(updateEventData);

  const timeOffRequestsActions = useActions(TimeOffRequestsActions);

  const handleSubstituteMenu = (e: any, data: any) => {
    const substituteArr:any = updateEvent.substituteArr;
    substituteArr[data.name] = data.value;
    setUpdateEvent({...updateEvent, substituteArr : substituteArr})
  }

  const handleOnChangeApprovalNote = (event: any) => {
    setUpdateEvent({...updateEvent, adminNotes: event.target.value});
  }

  const handleOnChangeApprovalStatus = (e: any, data: any) => {
    setUpdateEvent({...updateEvent, status: data.value});
  }

  const hideDentistListForm = () => {
    setState({ ...state, doctorsListModal : false})
  }

  const handleSubmit = () => {
    setLoader(true);
    const obj = {
      id: props.eventDetails.id,
      adminNotes : updateEvent.adminNotes,
      approvedBy : props.auth.user && props.auth.user.id,
      status : updateEvent.status,
      substituteArr : updateEvent.substituteArr ? updateEvent.substituteArr : {},
    }
    timeOffRequestsActions.updateRequest(obj)
      .then(() => {
        props.loadData();
        setLoader(false);
      })
  }

  const findDoctors = (userId:number, start: any, end:any) => {
    const startDate = moment(start).utc().format('YYYY-MM-DD');
    const endDate = moment(end).utc().format('YYYY-MM-DD');
    timeOffRequestsActions.findDoctors({startDate, endDate})
      .then((result: any) => {
        let dentistListArray:any = {};
        let availableDentists:any = new Array();
        let selectedDentist:any;
        Object.keys(props.dentistsList).filter((key:any) => {
          if(userId === props.dentistsList[key].UserID){
            selectedDentist = props.dentistsList[key];
          }
        });
        Object.keys(result).forEach(keys => {
          if (props.dentistsList && Object.keys(props.dentistsList).length) {
            if (!dentistListArray[keys]) {
              dentistListArray[keys] = [];
            }
            Object.keys(props.dentistsList).forEach(id => {

              const drItem = props.dentistsList[id];
              if (drItem && (result[keys].indexOf(drItem['id']) === -1 && drItem.dentistType === selectedDentist.dentistType)) {
                if ((drItem['firstName'] !== '' || drItem['lastName'] !== '') && drItem.role !== 'Admin' && selectedDentist.id !== parseInt(id)) {
                  dentistListArray[keys].push(drItem);
                  if (availableDentists.indexOf(id) === -1) {
                    availableDentists.push(id);
                  }
                }
              }
            });
          }
          // dentistListArray[keys] = _.sortBy(dentistListArray[keys], ['lastName']);
        });
        setState({...state,
          doctorsListModal: true,
          timeOffStartDate: startDate,
          timeOffEndDate: endDate,
          availableDentists: availableDentists,
          unscheduledDoctors: dentistListArray,
          selectedDentist: selectedDentist
        });
      })
  }

  const handleChangeRadio = (e:any, data: any) => {
    const substituteArr:any = updateEvent.substituteArr;
    if(data.checked) {
      substituteArr[data.value] = '';
    } else {
      delete substituteArr[data.value];
    }
    setUpdateEvent({...updateEvent, substituteArr : substituteArr})
  }


  const getSubstituteByOptions = () => {
    const startDate = moment(props.eventDetails.start, dateOnlyFormat);
    const endDate = moment(props.eventDetails.end, dateOnlyFormat);
    const dayLength =  endDate.diff(startDate, 'days');
    const html = new Array();
    for(let i = 0; i <= dayLength; i++) {
      const date = moment(props.eventDetails.start).utc().add(i, 'days').format(dateOnlyFormat)
      const substituteBy = updateEvent.substituteArr && updateEvent.substituteArr[date];
      html.push(
        <Grid.Row>
          <Grid.Column width={8}>
            <Form.Field>
              <label>Date</label>
              <Radio
                label={date}
                value={date}
                 checked={updateEvent.substituteArr[date] != undefined ? true : false}
                 onClick={handleChangeRadio}
              />
            </Form.Field>
          </Grid.Column>
          <Grid.Column width={8}>
            <Form.Field>
            <label>Substituted by</label>
            <div className="locationAndStatus">
              <Dropdown className="mr5"
                        placeholder='Find Doctor'
                        search selection
                        name = {date}
                        options={props.dentistOptions}
                        defaultValue={substituteBy}
                        onChange={(e, data)=>handleSubstituteMenu(e, data)}
              />
              <Button className={ substituteBy === -1 ? 'bgOrange' : (substituteBy > 0 ? 'bgGreen' : 'bgRed') }>{substituteBy === -1 ? 'Open' : (substituteBy > 0 ? 'Open' : 'Closed')}</Button>
            </div>
          </Form.Field>
          </Grid.Column>
        </Grid.Row>
      )
    }

    return html;

  }

  return (
      <>
      <Modal
        closeIcon
        className="timeOffRequest"
        open={true}
        onClose={() => props.onModalClose()}
      >
        <Modal.Header>{props.eventDetails.eventData.doctorName}
          <Label>{props.eventDetails.eventData.practice}</Label>
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Grid>
              <Grid.Row>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                  <Form.Field>
                    <Input fluid={true} label='Submitted By' placeholder={props.eventDetails.eventData.doctorName} />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                  <Form.Field>
                    <Input fluid={true} label='Submitted Date' placeholder={moment(props.eventDetails.eventData.createdAt).utc().format(dateOnlyFormat)}/>
                  </Form.Field>
                </Grid.Column>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                  <Form.Field>
                    <Input className="text-right" fluid={true} label='Number of Days' placeholder={props.eventDetails.eventData.days} />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                  <div className="customDatePicker">
                    <Form.Field>
                      <label>Time Off  <a href="#" className="float-right text-underline anchor"><Icon name='info' />Different dates proposed</a></label>
                      <div className="datePickerWrapper">
                        <Input defaultValue={moment(props.eventDetails.start).utc().format(dateOnlyFormat)} disabled={true}/>
                        <p>to</p>
                        <Input defaultValue={moment(props.eventDetails.end).utc().format(dateOnlyFormat)} disabled={true}/>
                      </div>
                    </Form.Field>
                  </div>
                </Grid.Column>

                <Grid.Column computer={8} tablet={8} mobile={16}>
                  <Form.Field>
                    <label>Approval</label>
                    <div className="locationAndStatus">
                      <Dropdown className="mr5"
                                placeholder='Approval status'
                                selection
                                name = {'status'}
                                options={approvalOptions}
                                defaultValue={updateEvent.status}
                                onChange={(e, data)=>handleOnChangeApprovalStatus(e, data)}
                      />
                    </div>
                  </Form.Field>
                </Grid.Column>

              </Grid.Row>

                  {getSubstituteByOptions()}

              <Grid.Row>
                <Grid.Column width={8}>
                  <Form.Field>
                    <label>Note</label>
                    <p>{props.eventDetails.eventData.notes ? props.eventDetails.eventData.notes : 'No note added'}.</p>
                  </Form.Field>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Button secondary onClick={()=> findDoctors(props.eventDetails.eventData.userRoleId, props.eventDetails.start, props.eventDetails.end)}>Find Doctors</Button>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                  <Form.Field>
                    <label>Approval By</label>
                    <Input defaultValue={props.eventDetails.eventData.approver} disabled={true}/>
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Form.Field>
                    <label>Approval Note</label>
                    <TextArea fluid={true}
                              defaultValue={props.eventDetails.eventData.adminNotes ? props.eventDetails.eventData.adminNotes : ''}
                              onBlur={(e:any)=>{ handleOnChangeApprovalNote(e)}}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Button loading={loader} disabled={loader} primary className="mr20" onClick={()=> {
                  handleSubmit();
                }}>Submit</Button>
                <Button primary className="mr20" onClick={()=> {
                  props.onModalClose();
                }}>Cancel</Button>
              </Grid.Row>
            </Grid>
          </Form>
        </Modal.Content>

      </Modal>
        {state.doctorsListModal && <DentistListModal selectedDentist={state.selectedDentist}
                                               hideDentistListForm={hideDentistListForm}
                                               unscheduledDoctors={state.unscheduledDoctors}
                                               availableDentists={state.availableDentists}
                                               timeoffDetails = {props.eventDetails.eventData}
        />}
        </>
    )
}

export default TimeOffPopup
