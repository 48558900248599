import React, {useEffect, useState} from "react"
import Template from "./Template"
import {useActions} from "../redux/actions";
import * as TimeOffRequestsActions from "../redux/actions/timeOffRequests";
import * as PracticesActions from "../redux/actions/practices";
import * as DentistActions from "../redux/actions/dentists";
import {RootState} from "../model";
import {connect} from "react-redux";
import Scheduler, {SchedulerData, ViewTypes} from 'react-big-scheduler';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import "react-big-calendar/lib/css/react-big-calendar.css";
import 'react-big-scheduler/lib/css/style.css'
import moment from 'moment'
import ReactDND from "./ReactDND";

import {
  timeOffStatusOptions,
  timeOffViewOptions,
  timeOffStatusColors,
  timeOffStatusLocationClr,
  viewOptions, viewBannerOptions
} from '../constants';
import {
  Dropdown,
  Modal,
  Grid,
  Card,
  List,
  Checkbox,
  Accordion,
  Icon,
  Input,
  Button, Header
} from "semantic-ui-react";
import TimeOffPopup from "./TimeOffPopup";
import HelpMark from "./HelpMark";
const dateFormat = 'YYYY-MM-DD h:mm:ss';

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const TimeOffRequests:React.FC<any>=(props)=>{
  const initialFilters : any  = {
      practice : [],
      month : moment().format(dateFormat),
      search : false,
      dentist :  [],
      status : -1,
      view : "",
      banners : 0,
      selectedRegion:0
  }

  const initialState:any = {
    bannerDetails : false,
    showDetails : false,
    eventDetails : false,
    activeIndex : 0,
    practiceList : false,
    dentistsList : false,
    showFilters : isMobile ? false : true,
    timeOffCount : 0,
    eventsData : false,
    pageView : 'scheduler',
    regionList:false,
    tempPracList:false,
    tempDentsList:false,
    dentistArry:[]
 
  }


  const timeOffRequestsActions = useActions(TimeOffRequestsActions);
  const practicesActions = useActions(PracticesActions);
  const dentistActions = useActions(DentistActions);
  const [filters, setFilters] = useState(initialFilters);
  const [state, setState] = useState(initialState);
  const [scheduler, setScheduler]: any = useState();

  const localizer = momentLocalizer(moment);
  const { auth } = props;

  // Static one resource to show on the calender
  const resources = [
    {
      id: 'r1',
      name: 'R1',
    }
  ];

  useEffect(() => {
    setTimeout(function () {
      const practiceList = practicesActions.getPracticeList();
      const dentistsList = dentistActions.getDoctorList();
      const regionList = practicesActions.getRegionList();
      Promise.all([practiceList, dentistsList,regionList]).then(values => {
        setState({...state,tempPracList:values[0] ,practiceList : values[0],tempDentsList:values[1] ,dentistsList  : values[1] , regionList:values[2]});
        setFilters({...filters,  view : "both"})
      });
    }, 0);
  }, []);

  useEffect(() => {
    state.practiceList && loadData();
  }, [filters]);


  const handleShowHideFilters = () => {
    const showHide = state.showFilters;
    setState({ ...state, showFilters : !showHide });
  }

  const handleClick = (e: any, titleProps: any ) => {
    const { index } = titleProps
    const newIndex = state.activeIndex === index ? -1 : index
    setState({...state, activeIndex: newIndex })
  }

  const loadData = () => {
    const schedulerData: any = new SchedulerData(moment(filters.month).format(dateFormat), ViewTypes.Month, false, false, {
      eventItemLineHeight : 62,
      monthCellWidth: 55,
    });
    let eventsData = new Array();
    const bannerData = new Array();

    const timeOffList = timeOffRequestsActions.getTimeOffRequestList(filters);
    const bannerList = timeOffRequestsActions.getBannerList(filters);
    Promise.all([timeOffList, bannerList]).then(values => {
      let timeOff;
      const banners = values[1];

      const {regionList} = state;
      const {selectedRegion}=filters;
      if(selectedRegion!=0){
      const rgpracticesId = regionList && regionList.regionPractices[selectedRegion];
       timeOff = values[0].filter((item:any)=>rgpracticesId.indexOf(parseInt(item.practiceId))>-1);
      }
       else{
        timeOff = values[0];
       }
      
      let count = 0;
      timeOff.forEach((item: any) => {
        count = count + 1;
        eventsData.push({
          id: item.id,
          start: moment(item.startDate).startOf('day').format(dateFormat),
          end: moment(item.endDate).endOf('day').format(dateFormat),
          resourceId: 'r1',
          title: eventTitle(item),
          eventData : item,
          bgColor : timeOffStatusColors && timeOffStatusColors[item.status],
          showPopover: false
        })
        state.dentistArry.push(item.userId);       
      })

      banners.forEach((item:any)=>{
        bannerData.push({
          id: item.id,
          start: moment(item.startDate).startOf('day').format(dateFormat),
          end: moment(item.endDate).endOf('day').format(dateFormat),
          resourceId: 'r1',
          title: bannerTitle(item.bannerTitle),
          banner : item,
          bgColor : 'red',
          type : 'banner'
        })
      })

      if(filters.banners){
        eventsData = eventsData.concat(bannerData)
      }
       
      let dentsObject = {} as any;
      if(selectedRegion!=0){       
        state.dentistArry.forEach((objitem:any)=>{ 
         dentsObject[objitem] =state.tempDentsList[objitem];
       });      
      }
      else{
        dentsObject = state.tempDentsList;
      }
    
      schedulerData.setResources(resources);
      schedulerData.setEvents(eventsData);
      setScheduler(schedulerData);
      setState({...state, timeOffCount : count, eventsData : eventsData, eventDetails: false, showDetails: false , dentistsList:dentsObject})

    });

  }

  const bannerTitle = (banner:string) => {
    return  <div className={'txtCenter bgRed'}> <p className="mb0">Banner</p></div>;

  }

  const eventTitle = (item: any) => {
    const practiceList = props.practiceList;
    let displayName = item.doctorName;
    let practiceData:any;
    let practice = item.practice;
    if(item.days < 2 && state.pageView === 'scheduler'){
      const name = item.doctorName && item.doctorName.split(" ");
      const firstName = name && name[0];
      const lastName = name && name[1];
      displayName = firstName && firstName.charAt(0) +' '+ lastName.charAt(0);
      practiceList && practiceList.filter((p: { practice: any; practiceabbr: any; }) => {
       if((p.practice && p.practice.toLowerCase()) === (item.practice && item.practice.toLowerCase())){
         practiceData = p;
       }
      });
      practice = practiceData && practiceData.practiceabbr && practiceData.practiceabbr.substring(3, 6);
    }
    switch (filters.view) {
      case 'both':
        return <div className={'txtCenter status'+item.status} style={{backgroundColor: timeOffStatusColors && timeOffStatusColors[item.status]}}><p className="drName mb0">{displayName}</p> <span className={'location '+timeOffStatusLocationClr[item.status]}>{practice}</span></div>;
        break;
      case 'practices':
        return <div className={'txtCenter status'+item.status} style={{backgroundColor: timeOffStatusColors && timeOffStatusColors[item.status]}}> <span className={'location '+timeOffStatusLocationClr[item.status]}>{practice}</span></div>;
        break;
      case 'providers':
        return <div className={'txtCenter status'+item.status} style={{backgroundColor: timeOffStatusColors && timeOffStatusColors[item.status]}}> <p className="drName mb0">{displayName}</p></div>;
        break;

    }
  }

  const prevClick = () => {
    const month = moment(filters.month).subtract(1,'month').format(dateFormat);
    setFilters({...filters, month : month});
    setState({...state , dentistArry:[]});
  }

  const nextClick = () => {
    const month = moment(filters.month).add(1,'month').format(dateFormat);
    setFilters({...filters, month : month});
    setState({...state , dentistArry:[]});
  }

  const onViewChange = (schedulerData: any, view: any) => {

  }

  const onSelectDate = (schedulerData: any, date: any) => {
    const month = moment(date).format(dateFormat);
    setFilters({...filters, month : month});
  }

  const eventClicked = (schedulerData: any, event: any) => {
    if(event.type === 'banner'){
      return;
    }
    setState({...state, eventDetails: event, showDetails: true });
  };

  const onModalClose = () => {
    setState({...state, eventDetails: false, showDetails: false });
  }

  const handleBannerClose = () => {
    setState({...state, bannerDetails: false });
  }

  const eventItemPopoverTemplateResolver = (schedulerData: any, eventItem: any, title: any) => {
    return (
      <div className={'banner'}>
        <p>{eventItem.banner && eventItem.banner.bannerTitle}</p>
        <span>{eventItem.banner && eventItem.banner.bannerDesc}</span>
      </div>
    );
  }

  const showDoctorList = () => {
    const { dentistsList } = state;
    const itemList = new Array();
    { dentistsList && Object.keys(dentistsList).forEach((item:any) => {
      const doctor = dentistsList && dentistsList[item];
      itemList.push(<List.Item>
        <Checkbox key={doctor.id} label={doctor.firstName +' '+doctor.lastName} value={doctor.id}
                  defaultChecked={filters.dentist && filters.dentist.indexOf(doctor.id) >= 0}
                  onChange={(e, data)=> handleDentistClick(data)}/>
      </List.Item>)
    })}
    return itemList;
  }

  const showPracticeList = () => {
    const { practiceList } = state;
    const itemList = new Array();
    { practiceList && practiceList.forEach((item:any) => {
      itemList.push(<List.Item>
        <Checkbox key = {item.id} label={item.practice} value={item.id}
                  defaultChecked={filters.practice && filters.practice.indexOf(item.id) >= 0}
                  onChange={(e, data)=> handlePracticeClick(data)}/>
      </List.Item>)
    })}
    return itemList;
  }

  const handleDentistClick = (data: any) => {
    const dentist = filters.dentist;
    if(data.checked){
      dentist.push(data.value);
    } else {
      const index = dentist.indexOf(data.value);
      dentist.splice(index, 1);
    }
    setFilters({...filters, dentist : dentist});
  }

  const handlePracticeClick = (data: any) => {
    const practice = filters.practice;
    if(data.checked){
      practice.push(data.value);
    } else {
      const index = practice.indexOf(data.value);
      practice.splice(index, 1);
    }
    setFilters({...filters, practice : practice});
  }

  const handlePracticeFilter = (e: any, value:any) => {
    let practiceList = props.practiceList;
    if(value){
      const praticeListFiltered =  practiceList.filter((element: any) => {
        return element.practice.search(new RegExp(value, "gi")) >= 0;
      });
      practiceList = praticeListFiltered;
    }
    setTimeout(function(){
      setState({...state, practiceList: practiceList});
    }, 500);
  }

  const handleDentistFilter = (e: any, value:any) => {
    let dentistsList = props.dentistsList&&Object.values(props.dentistsList);
    if(value){
      const dentistsListFiltered =  dentistsList&&dentistsList.filter((element: any) => {
        const string = element.firstName +' '+element.lastName;
        return string.search(new RegExp(value, "gi")) >= 0;
      });
      dentistsList = dentistsListFiltered;
    }
    setTimeout(function(){
      setState({...state, dentistsList: dentistsList});
    }, 500);
  }

  const handleStatusFilter = (e: any, data: any) => {
      setFilters({...filters, status : data});
    }

  const handleViewFilter = (e: any, data: any) => {
    setFilters({...filters, view : data});
  }

  const handleBanner = (e: any, data: any) => {
    setFilters({...filters, banners : data});
  }

  const handlePageView = (e: any, data: any) => {
    setState({...state, pageView : data});
  }
  const handleRegions = (e:any , data:any)=>{
    if(data!=0){
    const rgpracticesId =state.regionList && state.regionList.regionPractices[data];
    const practices = state.tempPracList.filter((prac:any)=> rgpracticesId.indexOf(prac.id)>-1);
    setState({...state ,practiceList : practices , dentistArry : []});
    }
    else{
      setState({...state ,practiceList : state.tempPracList, dentistsList:state.tempDentsList,dentistArry : [] });
    }
    setFilters({...filters, selectedRegion:data});
  

  }



  const onNavigate = (date: any) => {
    const month = moment(date).startOf('month').format(dateFormat);
    setFilters({...filters, month : month});
  }

  const onSelectCalendarEvent = (event:any) => {
    if(event.type ==='banner'){
      setState({...state, bannerDetails : event})
      return;
    }
    setState({...state, eventDetails: event, showDetails: true });

  }

  let { dentistsList } = props;
  const {regionList} = state;
  const { eventDetails , showDetails, bannerDetails} = state;
  let dentistOptions = [{ value: 0, text: "Select Doctor" }, { value: -1, text: "Run With One Doctor" }];
  const timeOffStatusOptionsArr = new Array();
  dentistsList && Object.keys(dentistsList).forEach((item: any) => {
    const dentist = dentistsList && dentistsList[item];
    dentistOptions.push({
      value: dentist.id, text: dentist.firstName+' '+dentist.lastName
    })
  });

  timeOffStatusOptions && timeOffStatusOptions.forEach((item:any) =>{
    timeOffStatusOptionsArr.push({
      key: item.key,
      value: item.key,
      text: <p><span className="colorLabel" style={{backgroundColor: item.color}}>{item.title}</span></p>
    });
  })

  let regionsOptions = [{ value: 0, text: "Select Region" }];
  regionList && regionList.regions && regionList.regions.forEach((item: any) => {
    regionsOptions.push({
      value: item.id, text:item.name
    })
  });

  return (
       <Template activeLink="time-off-requests" >

         {showDetails &&
         <TimeOffPopup
           onModalClose={onModalClose}
           dentistOptions={dentistOptions}
           auth = {auth}
           eventDetails ={eventDetails}
           loadData = {loadData}
           dentistsList = {dentistsList}
         />}
         { bannerDetails && <Modal
           closeIcon
           open={true}
           trigger={<Button>Show Modal</Button>}
           onClose={() => handleBannerClose()}
         >
           <Header icon='info circle' content={bannerDetails.banner && bannerDetails.banner.bannerTitle} />
           <Modal.Content>
             <p>
               {bannerDetails.banner && bannerDetails.banner.bannerDesc}
             </p>
           </Modal.Content>
         </Modal>}
         {state.showFilters && <div className="leftPanel">
           <Accordion>
             <Accordion.Title
               active={state.activeIndex === 0}
               index={0}
               onClick={(e, titleProps) => handleClick(e, titleProps)}
             >

               <h5 className="mb0"><Icon name='dropdown' />Doctors</h5>
             </Accordion.Title>
             <Accordion.Content active={state.activeIndex === 0}>
               <List>
                 <List.Item>
                 <h6 className="mb0 mt0">{filters.dentist && filters.dentist.length} of {state.dentistsList && Object.keys(state.dentistsList).length}</h6>
               </List.Item>
                 <List.Item>
                   <Input icon='search' placeholder='Search for a Doctor...'
                   onChange={(e, data)=>handleDentistFilter(e, data.value)}/>
               </List.Item>
                 {showDoctorList()}
               </List>
             </Accordion.Content>

             <Accordion.Title
               active={state.activeIndex === 1}
               index={1}
               onClick={(e, titleProps) => handleClick(e, titleProps)}
             >

               <h5 className="mb0"><Icon name='dropdown' />Practices</h5>
             </Accordion.Title>
             <Accordion.Content active={state.activeIndex === 1}>
               <List>
                 <List.Item>

                   <h6 className="mb0 mt0">{filters.practice && filters.practice.length} of { state.practiceList && state.practiceList.length}</h6>
                 </List.Item>

                 <List.Item>
                   <Input icon='search' placeholder='Search for a Practice...'
                          onChange={(e, data)=>handlePracticeFilter(e, data.value)}/>
                 </List.Item>
                 {showPracticeList()}
               </List>
             </Accordion.Content>

           </Accordion>
         </div> }
         <div className="content-right">
         <Grid>
           <Grid.Row>
           <Grid.Column>
             <Card>
               <Card.Content className="pb0">
                 <h2 className="headingMain left floated mr10">
                   Time Off Requests ({state.timeOffCount}) <HelpMark pageId='1'/></h2>
                 <div className="topFilters float-right">

                 <Dropdown className="right floated mb15 ml20"
                           placeholder='View'
                           selection
                           defaultValue={state.pageView}
                           options={viewOptions}
                           onChange={(e, data)=>handlePageView(e, data.value)}
                 />
                  <Dropdown className="right floated mb15"
                           placeholder='Select Region'
                           selection
                           options={regionsOptions}
                           onChange={(e, data)=>handleRegions(e, data.value)}
                 />
                 </div>
               </Card.Content>
             </Card>
           </Grid.Column>
           </Grid.Row>
           <Grid.Row>
             <Grid.Column>
               <Card>
                 <Card.Content>
                   <Grid>
                     <Grid.Row>
                       <Grid.Column>
                         <div className="filters">
                         <Button primary className="mr10 float-left mb15 btnFilterToggle" onClick={()=> {
                           handleShowHideFilters();
                         }}>{state.showFilters ? 'Hide ' : 'Show '}Filters</Button>
                         <Dropdown className="mr10 mb15 colorMenu"
                                   placeholder='Status'
                                   search selection
                                   options={timeOffStatusOptionsArr}
                                   onChange={(e, data)=>handleStatusFilter(e, data.value)}
                         />
                         <Dropdown className="mr10 mb15"
                                   placeholder='View'
                                   search selection
                                   options={timeOffViewOptions}
                                   onChange={(e, data)=>handleViewFilter(e, data.value)}
                         />
                         <Dropdown className="mr20 mb15"
                                   placeholder='Banner'
                                   selection
                                   defaultValue={filters.banners}
                                   options={viewBannerOptions}
                                   onChange={(e, data)=>handleBanner(e, data.value)}
                         />
                         </div>
                       </Grid.Column>
                     </Grid.Row>
                   </Grid>
                   <div className="table-wrapper">


               {scheduler && state.pageView === 'scheduler' && <Scheduler
                   schedulerData={scheduler}
                   prevClick={prevClick}
                   nextClick={nextClick}
                   onSelectDate={onSelectDate}
                   onViewChange={onViewChange}
                   eventItemClick={eventClicked}
                   eventItemPopoverTemplateResolver={eventItemPopoverTemplateResolver}
               />}
               { state.pageView === 'calendar' && state.eventsData && <Calendar
                 localizer={localizer}
                 events={state.eventsData}
                 startAccessor="start"
                 endAccessor="end"
                 style={{ height: 815 }}
                 popup={true}
                 onSelectEvent={event => onSelectCalendarEvent(event)}
                 onNavigate={(date) => onNavigate(date)}
               />}

                   </div>
                 </Card.Content>
               </Card>
             </Grid.Column>
           </Grid.Row>
         </Grid>
         </div>

       </Template>
    )
}

function mapStateToProps(state: RootState) {
  return {
    auth :state.auth,
    practiceList: state.practices.list,
    dentistsList: state.dentists.list,
    loader : state.app.loader
  };
}
export default connect(mapStateToProps)(ReactDND(TimeOffRequests));
