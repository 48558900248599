import React from 'react';
import { Icon, Popup } from "semantic-ui-react";

function Sorting(props: any) {
    const { order, handleClick, id, removeSort, orderBy } = props;
    return (
        <div style={{ display: 'inline' }}>
            <Icon
                className="cursorPointer"
                color={orderBy ? 'blue' : "black"}
                name={(order === 'ASC') ? 'sort amount up' : 'sort amount down'}
                onClick={() => handleClick([id, (order === 'ASC') ? 'DESC' : 'ASC'])}
            />

            <Popup position='bottom left' basic content={`Remove sorting on this column`} trigger={
                orderBy && <small className="cursorPointer" style={{ color: 'red' }}
                 onClick={() => removeSort(orderBy)}> x </small>
            } />
        </div>
    );
}

export default Sorting;
