import * as React from 'react';
import {Header, Label, Modal, Table} from "semantic-ui-react";
import moment from "moment";

const SelectedCDPDoctors: React.FC<any> = ({selected, close}) => {
    if (!selected) return <span/>
    return (
        <Modal
            closeIcon
            open={true}
            onClose={() => close()}
        >
            <Header icon='exchange' content='Substitute Doctors'/>
            <Modal.Content>
                <Table striped={true}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell content="Start"/>
                            <Table.HeaderCell content="Practice"/>
                            <Table.HeaderCell content="Doctor"/>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            selected && selected.length && selected.sort((a, b) => a.startDate - b.startDate).map(d =>
                                <Table.Row>
                                    <Table.Cell content={moment(d.startDate).format('DD MMM YYYY')}/>
                                    <Table.Cell content={d.practice}/>
                                    <Table.Cell>
                                        {
                                            d.doctors
                                            && d.doctors.map(doc => <Label color={doc.homeLocation == d.practice ? 'grey' : 'green'}
                                                                           detail={doc.homeLocation}
                                                                           content={`Dr ${doc.firstName} ${doc.lastName}`}/>)
                                        }
                                    </Table.Cell>
                                </Table.Row>)
                        }
                    </Table.Body>
                </Table>
            </Modal.Content>
        </Modal>
    )

}
export default SelectedCDPDoctors;
