import { Action, ActionType } from "../../model/actionType";
import { TimeOffList } from "../../model/timeOffRequests";
import createReducer from "./createReducer";
const initialState = {
};

export const timeOffRequests = createReducer(initialState, {
  [ActionType.LOAD_TIMEOFF_REQUESTS](state: TimeOffList, action: Action<TimeOffList>) {
    return Object.assign({ ...state }, action.payload);
  }
});
