import {ActionType} from "../../model/actionType"
import {errorHandler} from "./auth"

export const getTimeOffRequestList = (filters: any) => async (dispatch:Function,getState:Function,api:any)=>{
    dispatch({type:ActionType.LOADER,payload:true})
    try {
        const params = JSON.stringify(filters);
        const resp = await api.get(`time-off` +'?params=' +params);
        dispatch({ type: ActionType.LOAD_TIMEOFF_REQUESTS, payload:  resp  });
        dispatch({type:ActionType.LOADER,payload:false})
        return resp;

    } catch(err) {
        dispatch({type:ActionType.LOADER,payload:false})
        return dispatch(errorHandler(err))
    }
}

export const findDoctors = (params: any) => async (dispatch:Function,getState:Function,api:any)=>{
    const url = `time-off/findDoctors`;
    dispatch({type:ActionType.LOADER,payload:true})
    try {
        const param = JSON.stringify(params);
        const resp = await api.get(url +'?params=' +param);
        dispatch({ type: ActionType.LOAD_UNSCHEDULED_DOCTORS, payload:  resp  });
        dispatch({type:ActionType.LOADER,payload:false})
        return resp;

    } catch(err) {
        dispatch({type:ActionType.LOADER,payload:false})
        return dispatch(errorHandler(err))
    }
}


export const getBannerList = (filters: any) => async (dispatch:Function,getState:Function,api:any)=>{
    const url = `time-off/banners`;
    dispatch({type:ActionType.LOADER,payload:true})
    try {
        const params = JSON.stringify(filters);
        const resp = await api.get(url +'?params=' +params);
        dispatch({ type: ActionType.LOAD_BANNER_LIST, payload:  resp  });
        dispatch({type:ActionType.LOADER,payload:false})
        return resp;

    } catch(err) {
        dispatch({type:ActionType.LOADER,payload:false})
        return dispatch(errorHandler(err))
    }
}

export const updateRequest = (params : any) => async (dispatch:Function,getState:Function,api:any)=>{
    const url = `time-off`;
    dispatch({type:ActionType.LOADER,payload:true})
    try {
        const resp = await api.put(url, params);
        dispatch({ type: ActionType.UPDATE_TIME_OFF_REQUEST, payload:  resp  });
        dispatch({type:ActionType.LOADER,payload:false})
        return resp;

    } catch(err) {
        dispatch({type:ActionType.LOADER,payload:false})
        return dispatch(errorHandler(err))
    }
}

export const getAvailableDoctorsTimeOff = (param:any) => async (dispatch:Function,getState:Function,api:any)=>{
    const url = `time-off/getAvailableDoctorsTimeOff`;
    dispatch({type:ActionType.LOADER,payload:true})
    try {
        const params = JSON.stringify(param);
        const resp = await api.get(url +'?params=' +params);
        dispatch({ type: ActionType.GET_AVAILABLE_DENTIST_TIMEOFF, payload:  resp  });
        dispatch({type:ActionType.LOADER,payload:false})
        return resp;

    } catch(err) {
        dispatch({type:ActionType.LOADER,payload:false})
        return dispatch(errorHandler(err))
    }
}

export const getDentistDistanceFromLocation = (param:any) => async (dispatch:Function,getState:Function,api:any)=>{
    const url = `time-off/getDistanceFromLocation`;
    dispatch({type:ActionType.LOADER,payload:true})
    try {
        const params = JSON.stringify(param);
        const resp = await api.get(url +'?params=' +params);
        dispatch({ type: ActionType.GET_DISTANCE_FROM_LOCATION, payload:  resp  });
        dispatch({type:ActionType.LOADER,payload:false})
        return resp;

    } catch(err) {
        dispatch({type:ActionType.LOADER,payload:false})
        return dispatch(errorHandler(err))
    }
}

export const getReports = (param:any) => async (dispatch:Function,getState:Function,api:any)=>{
    const url = `time-off/reports`;
    dispatch({type:ActionType.LOADER,payload:true})
    try {
        const params = JSON.stringify(param);
        const resp = await api.get(url +'?params=' +params);
        dispatch({ type: ActionType.GET_REPORTS_TIMEOFF, payload: { timeOff:  resp }  });
        dispatch({type:ActionType.LOADER,payload:false})
        return resp;

    } catch(err) {
        dispatch({type:ActionType.LOADER,payload:false})
        return dispatch(errorHandler(err))
    }
}

export const fetchDoctorScheduleDays = (param:any) => async (dispatch:Function,getState:Function,api:any)=>{
    const url = `time-off/doctorScheduleDays`;
    dispatch({type:ActionType.LOADER,payload:true})
    try {
        const params = JSON.stringify(param);
        const resp = await api.get(url +'?params=' +params);
        dispatch({ type: ActionType.GET_REPORTS_DR_SCHD_DAYS, payload: { schDays:  resp }  });
        dispatch({type:ActionType.LOADER,payload:false})
        return resp;

    } catch(err) {
        dispatch({type:ActionType.LOADER,payload:false})
        return dispatch(errorHandler(err))
    }
}








