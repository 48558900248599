import * as React from 'react';
import {Header, Icon, Label, Message, Popup, Table} from "semantic-ui-react";
import moment from "moment";
import {DOCTOR_ID} from "../../constants";
import {getVal} from "../../helper";

const timeOffsColorMap = {1: 'green', 2: 'red', 99: 'grey', 3: 'violet', 4: 'purple'};

interface ITrackingTable {
    practices,
    trackingData,
    daysInMonth,
    currentMonth,
    onPracticeChange,
    selectSchedules,
    selectTimeOffs,
    activeIndex,
    dentistsList
}

const TrackingTable: React.FC<ITrackingTable> = (props: ITrackingTable) => {
    const {
        practices,
        trackingData,
        daysInMonth,
        currentMonth,
        onPracticeChange,
        selectSchedules,
        selectTimeOffs,
        activeIndex,
        dentistsList
    } = props;
    const getScheduledSubsPerPractice = (practice: any) => {
        if (trackingData.length === 0) return 0;
        const dataForPractice = trackingData.find(f => f.practice.id == practice.id) || {};
        const dataForSubstitute = dataForPractice.timeOffs.map(a => a.substitutes).flat().filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.id === value.id
                ))
        );
        let scheduledSubs = dataForSubstitute.filter((b: any) => {
            let dayOffData = new Date(b.timeOffDate).getMonth() + "-" + new Date(b.timeOffDate).getFullYear()
            let dayInMonth = new Date(daysInMonth[0]).getMonth() + "-" + new Date(daysInMonth[0]).getFullYear()
            let timeOffDay = dataForPractice.mapPerDay.filter(a => a.day == new Date(b.timeOffDate).getDate() && a.timeOffs.length > 0).length > 0
            return b.substituteBy > 0 && dayOffData === dayInMonth && timeOffDay;
        })
        return scheduledSubs.length
    }

    const calculateTotalFor = (params: any[], array?: string[]) => {
        const filteredTrackingData = practices.map(f => trackingData.find(({practice}) => practice.id == f.id));
        return ((array ?? filteredTrackingData) || []).reduce((acc, currVal) => acc + (getVal(currVal, params) || []).length, 0) || 0
    }

    const calculateTotalForScheduledSubs = () => {
        return practices.reduce((totalDays, practice: any) => {
            totalDays += getScheduledSubsPerPractice(practice) || 0
            return totalDays;
        }, 0);
    }

    const calculateTotalForDaysFilled = () => {
        const data = practices.reduce((totalDays, practice: any) => {
            const dataForPractice = trackingData.find(f => f.practice.id == practice.id) || {};
            totalDays[0] += (dataForPractice && dataForPractice.productions && dataForPractice.productions.length) || 0;
            totalDays[1] += (dataForPractice.mapPerDay || [])
                .filter(({day}) => moment(currentMonth).startOf('M').add(day, 'day').toDate() >= today)
                .reduce((acc, mapPerDay) => acc + (mapPerDay.schedule || []).length, 0);
            return totalDays;
        }, [0, 0]);
        return <>{data[0] + data[1]} (<span className="text-grey">{data[0]} of {data[1]}</span>)</>;
    }

    let totalDrPractice = 0;
    const today = new Date();
    today.setHours(0);
    const getPracticeRows = (practice, dataForPractice) => {
        return (daysInMonth && activeIndex == practice.id) && daysInMonth.map((date, i) => {
            const day = i + 1;
            const mapForDay = dataForPractice.mapPerDay.find(f => f.day == day) || {};
            const dataForSubstitute = mapForDay.timeOffs.map(a => a.substitutes).flat()
                .filter((value, index, self) => index === self.findIndex((t) => t.id === value.id));
            const scheduledSubs = dataForSubstitute.filter(b => b.substituteBy > 0 && new Date(b.timeOffDate).getDate() === day)
            const myArrayFiltered = dentistsList.filter((el) => scheduledSubs.some((f) => f.substituteBy === el.value));
            const productionDays = (mapForDay.production || []).map(item => ({
                name: "Dr. " + item.FName + " " + item.LName,
                id: item.ABBR,
                basic: true
            }));
            const scheduleDays = (mapForDay.schedule || []).filter(schedule => schedule.doctor).map(schedule => {
                return {
                    name: "Dr. " + schedule.doctor.firstName + " " + schedule.doctor.lastName,
                    id: schedule.doctor.abbr
                }
            });

            const hasWorked = productionDays && productionDays.length;
            const isPastDate = moment(currentMonth).startOf('M').add(day, 'day').toDate() < today;
            const daysFilledDr = (hasWorked ? productionDays : scheduleDays) || [];
            return <Table.Row negative={!!mapForDay.closed} key={i + '_row'}>
                <Table.Cell/>
                <Table.Cell>
                    {
                        mapForDay.holidays && mapForDay.holidays.length ?
                            mapForDay.holidays.map(holiday => <Icon
                                title={holiday.holiday_name}
                                name="umbrella"
                                color="grey"
                            />)
                            : <Icon title={!mapForDay.closed ? 'Work Day' : 'Closed'}
                                    name={!mapForDay.closed ? 'hospital outline' : 'dont'}
                                    color={!mapForDay.closed ? 'green' : 'grey'}/>
                    }

                    {moment(date).format('dddd Do')}
                </Table.Cell>

                <Table.Cell content={mapForDay.overrides.length &&
                    <Popup content={mapForDay.overrides.map(o => <Message header={o.override}
                                                                          content={o.description}/>)}
                           trigger={<Label content={mapForDay.overrides.length}
                                           basic={true}
                                           color="orange"/>}/> || ''}/>

                <Table.Cell >
                    {
                        !mapForDay.closed ?
                        <Label basic={true} content={mapForDay.drPractice + mapForDay.additionalDrDay} color="blue"/>
                        : mapForDay.additionalDrDay ?
                                <Label basic={true} content={mapForDay.additionalDrDay} color="teal"/>
                                : ''
                    }
                </Table.Cell>

                <Table.Cell
                    content={myArrayFiltered.length && myArrayFiltered.map(item => <Label
                        className="cursorPointer"
                        color={timeOffsColorMap[3] as any}
                        basic={true}
                        content={"Dr. " + item.text}/>) || ''}/>

                <Table.Cell
                    className="d-flex"
                    content={daysFilledDr.length && daysFilledDr.map(({id, name, basic}, i) =>
                        <Label
                            key={id + i + 'label'}
                            className="cursorPointer text-ellipsis"
                            title={id + '\n' + name}
                            color={timeOffsColorMap[(!hasWorked && isPastDate) ? 2 : basic ? 4 : 99] as any}
                            basic={true}
                            icon={basic ? 'check' : 'calendar alternate outline'}
                            size="small"
                            content={id}/>) || ''}/>

                <Table.Cell content={mapForDay.timeOffs.length && <Label
                    className="cursorPointer"
                    onClick={() => selectTimeOffs(mapForDay.timeOffs)}
                    color={timeOffsColorMap[99] as any}
                    content={mapForDay.timeOffs.length}/> || ''}/>

                {
                    [1, 2].map((key) => <Table.Cell
                        content={mapForDay.timeOffsByStatus[key] &&
                            <Label
                                className="cursorPointer"
                                onClick={() => selectTimeOffs(mapForDay.timeOffsByStatus[key])}
                                color={timeOffsColorMap[key]}
                                basic={true}
                                content={mapForDay.timeOffsByStatus[key].length}/> || ''}/>)
                }
                {
                    [DOCTOR_ID.Dr_EVANS, DOCTOR_ID.DR_COPELAND].map(id => <Table.Cell
                        content={mapForDay.schedulePerDr[id] ? <Label
                            className="cursorPointer"
                            basic={true}
                            onClick={() => selectSchedules(mapForDay.schedulePerDr[id])}
                            content={mapForDay.schedulePerDr[id].length}
                            color="orange"/> : ''}/>)
                }
                <Table.Cell>
                    {
                        mapForDay.homeLocationMap.NO &&
                        <Label
                            className="cursorPointer"
                            basic={true}
                            onClick={() => selectSchedules(mapForDay.homeLocationMap.NO)}
                            content={mapForDay.homeLocationMap.NO.length}/> || ''
                    }
                </Table.Cell>
            </Table.Row>
        })
    }

    const getPracticeHeader = ({practice, dataForPractice, scheduledSubs, drPractice, daysFilled, daysScheduled}) => {
        return <Table.Row>
            <Table.Cell className="cursorPointer"
                        onClick={() => onPracticeChange(undefined, {value: practice.id})}>
                <Icon
                    name={activeIndex == practice.id ? 'angle down' : 'angle right'}/> {practice.practice}
            </Table.Cell>
            <Table.Cell>
                {
                    practice && practice.days && <b>{practice.days}</b> || ''
                }
            </Table.Cell>
            <Table.Cell content={dataForPractice.overrides.length &&
                <Label content={dataForPractice.overrides.length} color="orange"/> || ''}/>
            <Table.Cell>
                {drPractice && <>
                    <Label content={drPractice + dataForPractice.additionalDrDays} color="blue"/>
                    {dataForPractice.additionalDrDays &&
                        <Label color="blue" basic content={`${drPractice} + ${dataForPractice.additionalDrDays}`}/> || ''}
                </> || ''}
            </Table.Cell>
            <Table.Cell content={scheduledSubs && <Label
                className="cursorPointer"
                color={timeOffsColorMap[3] as any}
                content={scheduledSubs}/> || ''}/>

            <Table.Cell content={(daysFilled || daysScheduled) && <Label.Group>
                {daysFilled && <Label content={daysFilled} color={timeOffsColorMap[4] as any}/> || ''}
                {daysScheduled && <Label icon="calendar alternate outline" basic={true} content={daysScheduled}
                                         color="grey"/> || ''}
            </Label.Group> || ''}/>

            <Table.Cell content={dataForPractice.timeOffs.length && <Label
                className="cursorPointer"
                onClick={() => selectTimeOffs(dataForPractice.timeOffs)}
                color={timeOffsColorMap[99] as any}
                content={dataForPractice.timeOffs.length}/> || ''}/>

            {
                [1, 2].map((key, j) => <Table.Cell key={j + key + daysFilled + ''}
                                                   content={dataForPractice.timeOffsByStatus[key] &&
                                                       <Label
                                                           className="cursorPointer"
                                                           onClick={() => selectTimeOffs(dataForPractice.timeOffsByStatus[key])}
                                                           color={timeOffsColorMap[key]}
                                                           content={dataForPractice.timeOffsByStatus[key].length}/> || ''}/>)
            }
            {
                [DOCTOR_ID.Dr_EVANS, DOCTOR_ID.DR_COPELAND].map(id => <Table.Cell key={id + daysScheduled + ''}
                                                                                  content={dataForPractice.schedulePerDr[id] ?
                                                                                      <Label
                                                                                          className="cursorPointer"
                                                                                          onClick={() => selectSchedules(dataForPractice.schedulePerDr[id])}
                                                                                          content={dataForPractice.schedulePerDr[id].length}
                                                                                          color="orange"/> : ''}/>)
            }
            <Table.Cell>
                {
                    dataForPractice.homeLocationMap.NO &&
                    <Label
                        className="cursorPointer"
                        onClick={() => selectSchedules(dataForPractice.homeLocationMap.NO)}
                        content={dataForPractice.homeLocationMap.NO.length}/> || ''
                }
            </Table.Cell>
        </Table.Row>
    }

    return <Table.Body>
        {
            practices.map((practice) => {
                const dataForPractice = trackingData.find(f => f.practice.id == practice.id) || {};
                const drPracticeMapForDay = daysInMonth.map((_date, i) => dataForPractice.mapPerDay.find(f => f.day == (i + 1)) || {})
                const drPractice = drPracticeMapForDay.reduce((total, obj) => total + (obj && obj.closed === 0 ? obj.drPractice : 0), 0);
                totalDrPractice += (drPractice + dataForPractice.additionalDrDays);
                const dataForSubstitute = dataForPractice.timeOffs.map(a => a.substitutes).flat()
                    .filter((value, index, self) => index === self.findIndex((t) => t.id === value.id));
                const scheduledSubs = dataForSubstitute.filter(b => {
                    let dateOffData = new Date(b.timeOffDate).getMonth() + "-" + new Date(b.timeOffDate).getFullYear()
                    let dayInMonth = new Date(daysInMonth[0]).getMonth() + "-" + new Date(daysInMonth[0]).getFullYear()
                    let timeOffDay = dataForPractice.mapPerDay.filter(a => a.day == new Date(b.timeOffDate).getDate() && a.timeOffs.length > 0).length > 0
                    return b.substituteBy > 0 && dateOffData === dayInMonth && timeOffDay;
                }).length;
                const daysFilled = (dataForPractice.productions || []).length;
                const daysScheduled = (dataForPractice.mapPerDay || [])
                    .filter(({day}) => moment(currentMonth).startOf('M').add(day, 'day').toDate() >= today)
                    .reduce((acc, mapPerDay) => acc + (mapPerDay.schedule || []).length, 0);
                const practiceHeader = getPracticeHeader({
                    practice,
                    dataForPractice,
                    scheduledSubs,
                    drPractice,
                    daysScheduled,
                    daysFilled
                });
                const practiceData = getPracticeRows(practice, dataForPractice);
                return [practiceHeader, practiceData];
            })
        }
        {
            practices &&
            <Table.Row>
                <Table.Cell> <Header as="h3">Totals</Header></Table.Cell>
                <Table.Cell></Table.Cell>
                {
                    [
                        calculateTotalFor(['overrides']),
                        totalDrPractice,
                        calculateTotalForScheduledSubs(),
                        calculateTotalForDaysFilled(),
                        calculateTotalFor(['timeOffs']),
                        calculateTotalFor(['timeOffsByStatus', 1]),
                        calculateTotalFor(['timeOffsByStatus', 2]),
                        calculateTotalFor(['schedulePerDr', DOCTOR_ID.Dr_EVANS]),
                        calculateTotalFor(['schedulePerDr', DOCTOR_ID.DR_COPELAND]),
                        calculateTotalFor(['homeLocationMap', 'NO'])
                    ].map((value, i) => <Table.Cell key={i + 'cell_total'} content={<b>{value}</b>}/>)
                }
            </Table.Row>
        }
    </Table.Body>;
}
export default TrackingTable;
