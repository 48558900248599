
export enum ActionType {
    //app-auth
    LOGIN_BEGIN,
    LOGIN_SUCCESS,
    LOGOUT,
    LOAD_SESSION,
    ALERT,
    LOADER,
    SEND_FEEDBACK,
    SAVE_USAGE,
    SIDEBAR_OPEN,
    LOAD_MYCDP_URI,
    LOAD_PRACTICES,
    LOAD_REGIONS,
    LOAD_DOCTORS,
    // REPORTS SECTION
    GET_REPORTS_TIMEOFF,
    GET_REPORTS_DR_SCHD_DAYS,
    // Time off requests
    LOAD_TIMEOFF_REQUESTS,
    UPDATE_TIME_OFF_REQUEST,
    LOAD_BANNER_LIST,
    LOAD_UNSCHEDULED_DOCTORS,
    GET_AVAILABLE_DENTIST_TIMEOFF,
    GET_DISTANCE_FROM_LOCATION,
    LOAD_SCHEDULE,
    LOAD_OVERRIDE_DAY,
    LOAD_BRANDS,
    LOAD_DOCTOR_DAYS
}



export interface Action<T> {
    type: ActionType;
    payload: T;
  }
  