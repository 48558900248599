import { ActionType } from "../../model/actionType";

export const LogIn = (profileObj: any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		dispatch({ type: ActionType.LOGIN_BEGIN, payload: {} });
		const resp = await api.post("sessions?jwt=1",  profileObj);
		dispatch({ type: ActionType.ALERT, payload: {} });
		dispatch({ type: ActionType.LOGIN_SUCCESS, payload: resp });

		return resp;
	} catch (err) {
		return dispatch({ type: ActionType.ALERT, payload: err });
	}
};

export const LogOut = () => async (dispatch: Function, getState: Function, api: any) => {
	api.delete("sessions");
	dispatch({ type: ActionType.LOGOUT, payload: {} });
};

export const Load = () => async (dispatch: Function, getState: Function, api: any) => {
	try {
		const resp = await api.get("sessions");
		if(!resp || !resp.id) {
			return dispatch(LogOut());
		}
	} catch (err) {
		return dispatch(LogOut());
	}
};

export const getPractices = () => async (dispatch: Function, getState: Function, api:any) => {
    try {
        return await api.get("practicesInfo/getPractices");
    } catch (err) {
        return dispatch({ type: ActionType.ALERT, payload: err });
    }
};

export const clearError = ()=> (dispatch: Function) => {
		return dispatch({ type: ActionType.ALERT, payload: null });
};

export const errorHandler = (error: any) => (dispatch: Function) => {
	if (error.message === 'Session Expired' || error.statusCode === 401) {
		return dispatch(LogOut());
	}
	return dispatch({ type: ActionType.ALERT, payload: error });
};

export const LogInWithForm = (obj: any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		dispatch({ type: ActionType.LOGIN_BEGIN, payload: {} });
		const resp = await api.post("sessions/login?jwt=1",  obj);
		dispatch({ type: ActionType.ALERT, payload: {} });
		dispatch({ type: ActionType.LOGIN_SUCCESS, payload: resp });
		return resp;
	} catch (err) {
		 dispatch({ type: ActionType.ALERT, payload: err });
		 return err;
	}
};
export const cdpLogin = (accessToken:any) => async (dispatch:Function, getState: Function, api:any) => {
	try {
		dispatch({ type: ActionType.LOGIN_BEGIN, payload: {} });

		const resp = await api.get(`sessions/cdpLogin/${accessToken}`);
		dispatch({ type: ActionType.ALERT, payload: {} });
		dispatch({ type: ActionType.LOGIN_SUCCESS, payload: resp });

		return resp;
	} catch (err) {
		return dispatch({ type: ActionType.ALERT, payload: err });
	}
	
};
export const getDentalPartners = (id:any) => async (dispatch:Function, getState: Function, api:any) => {
	try {
		const resp = await api.get(`getPartnersList/${id}`, {});
		dispatch({ type:ActionType.LOAD_MYCDP_URI, payload: resp.partners_uri });
		return resp;
	} catch (err) {
		return err;
	}
};

export const getGoogleUserDetails =
	(token: string) =>
		async (dispatch: Function, _getState: Function, api: any) => {
			try {
				const resp = await api.getGoogle(
					`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`,
					"",
					"",
					token,
				);
				return resp;
			} catch (err) {
				return null;
			}
		};