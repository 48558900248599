import { Action, ActionType } from "../../model/actionType";
import {  Reports } from "../../model/reports";
import createReducer from "./createReducer";
const initialState = {
};

export const reports = createReducer(initialState, {
  [ActionType.GET_REPORTS_TIMEOFF](state: Reports, action: Action<Reports>) {
    return Object.assign({ ...state }, action.payload);
  },
  [ActionType.GET_REPORTS_DR_SCHD_DAYS](state: Reports, action: Action<Reports>) {
    return Object.assign({ ...state }, action.payload);
  },
});
