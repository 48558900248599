import React from 'react'
import { Button, Modal, Message, Header} from 'semantic-ui-react'


interface ErrorModalProps {
    errorMessage:string
    hideDetailsModal:any
}
const ErrorModal:React.FC<ErrorModalProps>=(props)=>{
   return (
    <Modal
    open={true}
    onClose={props.hideDetailsModal}
    centered={false}
    size='small'
  >
    <Modal.Header color="red" icon='warning' content='Error' />
    <Modal.Content>
      <Message negative>
          <p>{props.errorMessage}</p>
        </Message>
    </Modal.Content>
    <Modal.Actions>
      <Button color='red' onClick={props.hideDetailsModal}>
        Close
      </Button>
    </Modal.Actions>
  </Modal>
   )
}

export default ErrorModal
