import React, { Component } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

interface LoadingProps {
  desc?: string;
}

interface LoadingState {
  timer: number;
}

export class Loading extends Component<LoadingProps, LoadingState> {
  constructor(props: LoadingProps) {
    super(props);
    this.state = { timer: 0 };
  }

  startCounting = () => {
    setInterval(() => {
      this.setState((prevState) => ({
        timer: prevState.timer + 1,
      }));
    }, 1000);
  };

  componentDidMount() {
    this.startCounting();
  }

  formatTime = () => {
    const { timer } = this.state;
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;

    return minutes === 0 ? `${seconds}s` : `${minutes}m ${seconds}s`;
  };

  render() {
    return (
        <div>
          <Dimmer active inverted>
            <Loader size="massive">
              {!this.props.desc && this.formatTime()}
            </Loader>
          </Dimmer>
        </div>
    );
  }
}