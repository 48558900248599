import React, {useEffect, useState} from "react"
import Template from "./Template"
import {RootState} from "../model";
import {connect} from "react-redux";
import {useActions} from "../redux/actions";
import * as PracticesActions from "../redux/actions/practices";
import * as DentistsActions from "../redux/actions/dentists";
import * as TimeOffRequestsActions from "../redux/actions/timeOffRequests";
import moment from 'moment';
import {Dropdown, Input, Table} from "semantic-ui-react";
import {dentistTypesOptions, monthsArray} from "../constants";
import {Loading} from "./Loading";
import _ from "lodash";
import HelpMark from "./HelpMark";

const TimeOffRequestReport: React.FC<any> = (props) => {
    const initialFilters: any = {
        dentistType: false,
        years: moment().format('Y'),
        months: [moment().format('MM')],
        keyword: '',
        dentist: false,
        practice: [],
        region: ''
    }

    const initialState: any = {
        sortBy: {
            column: 'startDate',
            order: true
        },
        yearMonthsList: [],
        clickedColumn: false,
    }


    const [filters, setFilters] = useState(initialFilters);
    const [state, setState] = useState(initialState);
    const [loader, setLoader] = useState(false);
    const practicesActions = useActions(PracticesActions);
    const dentistActions = useActions(DentistsActions)
    const timeOffRequestsActions = useActions(TimeOffRequestsActions)


    useEffect(() => {
        setLoader(true);
        practicesActions.getPracticeList();
        dentistActions.getDoctorList();
        practicesActions.getRegionList();
        fetchData(true);
        getYearMonthsList();
    }, []);

    const handleSort = (clickedColumn: string) => {
        const {column, order} = state.sortBy;
        if (column !== clickedColumn) {
            setState({
                ...state,
                sortBy: {
                    column: clickedColumn,
                    order: true
                }
            })

            return
        }

        setState({
            ...state,
            sortBy: {
                column: clickedColumn,
                order: !order
            }
        })
    }

    const fetchData = (updateBcare: boolean) => {
        timeOffRequestsActions.getReports({filters, sortBy: state.sortBy}, updateBcare);
        updateBcare && timeOffRequestsActions.fetchDoctorScheduleDays({filters}).then(() => setLoader(false));
    }

    const getData = (reportsTimeOff, reportsSchDays) => {
        const {column, order} = state.sortBy;
        let data: any = reportsTimeOff.map((item: any) => {
            const drAbbr = item.Doctor && item.Doctor.abbr && item.Doctor.abbr.toLowerCase();
            const drID = item.Doctor && item.Doctor.id;
            const dentistType = item.Doctor && item.Doctor.dentistType;
            const drName = item.doctorName;
            const contractName = item.Doctor && item.Doctor.UdaDrList && item.Doctor.UdaDrList.contractName;
            const pracAbbr = item.Practice && item.Practice.practiceabbr && item.Practice.practiceabbr.toLowerCase();
            const pracDBName = item.Practice && item.Practice.practiceDBName;
            const pracName = item.Practice && item.Practice.practice || '';
            const workedDays = reportsSchDays && reportsSchDays.bcareData && reportsSchDays.bcareData[drAbbr] && (reportsSchDays.bcareData[drAbbr][pracAbbr] ? reportsSchDays.bcareData[drAbbr][pracAbbr] : reportsSchDays.bcareData[drAbbr][pracDBName]);
            const offeredDays = reportsSchDays && reportsSchDays.scheduleData && reportsSchDays.scheduleData[drID] && reportsSchDays.scheduleData[drID][pracName];

            if (filters.dentist && filters.dentist !== drID) {
                return undefined;
            }

            if (filters.dentistType && !filters.dentistType.includes(dentistType)) {
                return undefined;
            }

            if (filters.keyword) {
                const drNameFind = drName.toLowerCase().includes(filters.keyword.toLowerCase());
                const pracNameFind = pracName.toLowerCase().includes(filters.keyword.toLowerCase())
                if (!drNameFind && !pracNameFind) {
                    return undefined;
                }
            }
            return {
                doctorName: drName,
                contractName: contractName,
                practice: pracName,
                offeredDays: offeredDays ? offeredDays : 0,
                workedDays: workedDays ? workedDays : 0,
                rtoDenied: item.rtoDenied,
                rtoApproved: item.rtoApproved,
                rtoForced: item.rtoForced,
            }
        }).filter(f => !!f);
        data = _.sortBy(data, [column]);
        return order ? data : data.reverse();
    }

    const getTableBody = (data: any[] = []) => {
        return data.map((item: any, key: any) => {
            return <Table.Row key={`${item.doctorName + key}`}>
                <Table.Cell>{item.doctorName}</Table.Cell>
                <Table.Cell>{item.contractName}</Table.Cell>
                <Table.Cell>{item.practice}</Table.Cell>
                <Table.Cell>{item.offeredDays}</Table.Cell>
                <Table.Cell>{item.workedDays}</Table.Cell>
                <Table.Cell>{item.rtoDenied}</Table.Cell>
                <Table.Cell>{item.rtoApproved}</Table.Cell>
                <Table.Cell>{item.rtoForced}</Table.Cell>
            </Table.Row>;
        });
    }

    const getTotalsRow = (data: any[] = []) => {
        const totals = data.reduce((total, item) => {
            total.rtoForced += parseInt(item.rtoForced) || 0;
            total.rtoApproved += parseInt(item.rtoApproved) || 0;
            total.offeredDays += +item.offeredDays || 0;
            total.workedDays += +item.workedDays || 0;
            total.rtoDenied += parseInt(item.rtoDenied);
            return total;
        }, {rtoDenied: 0, workedDays: 0, offeredDays: 0, rtoApproved: 0, rtoForced: 0})

        return <Table.Header>
            <Table.Row key={`${Number.MAX_SAFE_INTEGER}`}>
                <Table.HeaderCell><b>{'Total:'}</b></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell><b>{totals.offeredDays}</b></Table.HeaderCell>
                <Table.HeaderCell><b>{totals.workedDays}</b></Table.HeaderCell>
                <Table.HeaderCell><b>{totals.rtoDenied}</b></Table.HeaderCell>
                <Table.HeaderCell><b>{totals.rtoApproved}</b></Table.HeaderCell>
                <Table.HeaderCell><b>{totals.rtoForced}</b></Table.HeaderCell>
            </Table.Row>
        </Table.Header>
    }

    const getYearMonthsList = () => {
        let returnObj: any = {years: [], months: []}
        for (let i = -1; i <= 5; i++) {
            const year = moment().subtract(i, 'years').format('Y');
            returnObj.years.push({
                value: year,
                text: year,
                key: year
            });
        }
        monthsArray.forEach((month, key) => {
            returnObj.months.push({
                value: (key < 9 ? "0" : "") + (key + 1),
                text: month,
                key: (key < 9 ? "0" : "") + (key + 1),
            });
        })
        setState({...state, yearMonthsList: returnObj});

    }

    const onChangeAutoFilter = (e: any, element: any) => {
        setLoader(true);
        setFilters({...filters, [element.name]: element.value});
        setTimeout(function () {
            setLoader(false);
        }, 500);

    }

    const onChangeFilter = (e: any, element: any) => {
        const {regions} = props;
        let updateBcare = true;
        if (element.name === 'region' && regions.regionPractices[element.value]) {
            const practicesIds = regions.regionPractices[element.value];
            const practices = practiceList.filter(p => practicesIds.includes(p.id)).map(o => o.practice.toLowerCase())
            filters.practice = practices;
        }
        if (element.name === 'region' && !element.value) {
            filters.practice = [];
        }
        if (element.name === 'region' || element.name === 'practice') {
            updateBcare = false
        }
        filters[element.name] = element.value;
        if (element.name === 'practice' && element.value && element.value.includes(false) && element.value.length > 1) {
            if (!filters.practice[0]) {
                filters.practice = filters.practice.filter((ele: any) => ele);
            } else {
                filters.practice = [false];
            }
        }
        setFilters({...filters});
        fetchData(updateBcare);
    }


    const {column, direction, yearMonthsList} = state;
    const {reportsTimeOff, page, auth, practiceList, regions, doctorList, reportsSchDays} = props;

    const practiceOptions: any[] = [];
    practiceOptions.push({key: 'all', value: false, text: 'All'});
    practiceList && practiceList.forEach((practice: any) => {
        practiceOptions.push({
            text: practice.practice,
            value: practice.practice.toLowerCase(),
            key: practice.id
        });
    });

    const dentistOptions: any[] = [];
    dentistOptions.push({key: 'all', value: false, text: 'All'});
    doctorList && Object.keys(doctorList).forEach(id => {
        const item = doctorList[id];
        dentistOptions.push({
            text: item.firstName + ' ' + item.lastName,
            value: item.id,
            key: item.id
        });
    });

    const regionListOptions: any[] = [];
    regionListOptions.push({key: 'all', value: false, text: 'All'});
    regions && regions.regions && regions.regions.forEach((item: any) => {
        regionListOptions.push({text: item.name, value: item.id, key: item.id})
    });

    const data = (reportsTimeOff && reportsSchDays && reportsSchDays.bcareData && reportsTimeOff.length) ? getData(reportsTimeOff, reportsSchDays) : [];

    return (
        <Template activeLink="timeoff-request-report">
            {loader && <Loading/>}
            <div className="col-12">
                <div className="float-right topDropdown">
                    <Dropdown
                        search
                        className='mr10 mb10'
                        name="years"
                        placeholder="Select Year"
                        selection
                        value={filters.years}
                        options={yearMonthsList.years}
                        onChange={onChangeFilter}
                    />
                    <Dropdown
                        search
                        className='mr10 mb10'
                        name="months"
                        placeholder="Select Month"
                        selection
                        multiple
                        value={filters.months}
                        options={yearMonthsList.months}
                        onChange={onChangeFilter}
                    />
                    {auth.user.isAdmin &&
                        <Dropdown
                            search
                            className='mr10 mb10'
                            name="dentistType"
                            placeholder="Dentist Type"
                            selection
                            options={dentistTypesOptions}
                            onChange={onChangeAutoFilter}
                            value={filters.dentistType}
                        />}
                    {auth.user.isAdmin &&
                        <Dropdown
                            search
                            className='mr10 mb10'
                            name="dentist"
                            placeholder="Provider"
                            selection
                            options={dentistOptions}
                            onChange={onChangeAutoFilter}
                        />}
                    <Dropdown
                        search
                        className='mr10 mb10'
                        name="practice"
                        placeholder="Practice"
                        selection
                        multiple
                        value={filters.practice}
                        options={practiceOptions}
                        onChange={onChangeFilter}
                    />
                    <Dropdown
                        search
                        className='mr10 mb10'
                        name="region"
                        placeholder='Region Filter'
                        selection
                        options={regionListOptions}
                        onChange={onChangeFilter}
                    />

                    <Input
                        onChange={onChangeAutoFilter}
                        name="keyword"
                        placeholder='Search'
                    />
                </div>
                <h2>{page === 'offeredWorkDays' ? "Offered Work Days" : "Time off Request Summary"}
                    <HelpMark pageId='1'/></h2>
            </div>

            <Table striped={true} compact={true}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell
                            className={`FreezeTable-first-child cursorPointer ${column === 'doctorName' ? 'active' : ''}`}
                            onClick={() => handleSort('doctorName')}>
                            <div>
                                Provider Name
                                <i className={`sort amount ${direction} icon ml-05`}></i>
                            </div>
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            className={`FreezeTable-first-child cursorPointer ${column === 'contractName' ? 'active' : ''}`}
                            onClick={() => handleSort('contractName')}>
                            <div>
                                Contract Name
                                <i className={`sort amount ${direction} icon ml-05`}></i>
                            </div>
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            className={`FreezeTable-first-child cursorPointer ${column === 'practice' ? 'active' : ''}`}
                            onClick={() => handleSort('practice')}>
                            <div>
                                Practice
                                <i className={`sort amount ${direction} icon ml-05`}></i>
                            </div>
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            className={`FreezeTable-first-child cursorPointer ${column === 'offeredDays' ? 'active' : ''}`}
                            onClick={() => handleSort('offeredDays')}>
                            <div>
                                Offered Days
                                <i className={`sort amount ${direction} icon ml-05`}></i>
                            </div>
                        </Table.HeaderCell>
                        {page !== 'offeredWorkDays' ?
                            <Table.HeaderCell
                                className={`FreezeTable-first-child cursorPointer ${column === 'workedDays' ? 'active' : ''}`}
                                onClick={() => handleSort('workedDays')}>
                                <div>
                                    Worked Day
                                    <i className={`sort amount ${direction} icon ml-05`}></i>
                                </div>
                            </Table.HeaderCell> : ""}
                        {page !== 'offeredWorkDays' ?
                            <Table.HeaderCell className={`cursorPointer ${column === 'rtoDenied' ? 'active' : ''}`}
                                              onClick={() => handleSort('rtoDenied')}>
                                <div>
                                    RTO - Denied
                                    <i className={`sort amount ${direction} icon ml-05`}></i>
                                </div>
                            </Table.HeaderCell> : ""}
                        <Table.HeaderCell className={`cursorPointer ${column === 'rtoApproved' ? 'active' : ''}`}
                                          onClick={() => handleSort('rtoApproved')}>
                            <div>
                                RTO - Approved
                                <i className={`sort amount ${direction} icon ml-05`}></i>
                            </div>
                        </Table.HeaderCell>
                        <Table.HeaderCell className={`cursorPointer ${column === 'rtoForced' ? 'active' : ''}`}
                                          onClick={() => handleSort('rtoForced')}>
                            <div>
                                RTO - Forced
                                <i className={`sort amount ${direction} icon ml-05`}></i>
                            </div>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        data && data.length && getTableBody(data) ||
                        <Table.Row>
                            <Table.Cell>No record found.</Table.Cell>
                        </Table.Row>
                    }
                </Table.Body>
                {
                    data && data.length && getTotalsRow(data) || ''
                }
            </Table>

        </Template>
    )
}

function mapStateToProps(state: RootState) {
    return {
        auth: state.auth,
        reportsTimeOff: state.reports.timeOff,
        practiceList: state.practices.list,
        doctorList: state.dentists.list,
        regions: state.practices.regions,
        reportsSchDays: state.reports.schDays,
        loader: state.app.loader
    };
}

export default connect(mapStateToProps)(TimeOffRequestReport);
