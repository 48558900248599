import * as React from 'react';
import {useState, useEffect} from 'react';
import {Button, Dropdown, Form, Icon, Label, Pagination, Table} from 'semantic-ui-react';
import Template from '../Template';
import {useActions} from "../../redux/actions";
import * as practiceActions from "../../redux/actions/practices";
import * as adjustmentActions from "../../redux/actions/scheduleAdjustments";
import 'react-datepicker/dist/react-datepicker.css'
import moment from "moment";
import SaveModal from "./SaveModal";
import {IScheduleAdjustment} from "../../model/ScheduleAdjustment";
import {RECURRENCE_OPTIONS} from "../../constants";
import ReactDatePicker from "react-datepicker";

const HEADERS = [
    {title: 'Practice', value: 'practice'},
    {title: 'Start Date', value: 'start'},
    {title: 'End Date', value: 'end'},
    {title: 'Additional Dr Days', value: 'additionalDays'},
    {title: 'Recurrence', value: 'recurrence'},
    {title: 'Week Days', value: 'days'},
    {title: 'Override Config', value: 'config'}
];
const PAGE_SIZE = 10;

interface IPageData {
    practice: string;
    start: Date;
    end: Date;
    additionalDays: number;
    recurrence: string;
    days: string[];
    config: boolean;
    adjustment: IScheduleAdjustment;
}

const PracticeAdjustment: React.FC<any> = () => {
    const initialState = {
        adjustment: undefined as any,
        adjustments: [] as IScheduleAdjustment[]
    };
    const initialFilter = {
        practices: [] as any[],
        practice: undefined as string | undefined,
        start: undefined as Date | undefined,
        end: undefined as Date | undefined
    }

    const [state, setStates] = useState(initialState);
    const [filterState, setFilterState] = useState(initialFilter);
    const [pageState, setPageState] = useState({page: 0, count: 0, data: [] as IPageData[]});
    const [sortState, setSortState] = useState({value: undefined as any as string, ascending: false});

    const {adjustment} = state;
    const {practices} = filterState;

    const practiceAction = useActions(practiceActions);
    const adjustmentAction = useActions(adjustmentActions);
    useEffect(() => {
        loadOptions();
        loadData();
    }, []);

    useEffect(() => {
        setPage(pageState.page);
    }, [sortState, filterState.practice, filterState.start, filterState.end])

    const loadOptions = async () => {
        const practices = await practiceAction.getPracticeList();
        setFilterState({...filterState, practices});
    }

    const loadData = async () => {
        const adjustments = await adjustmentAction.get({isDeleted: false});
        setStates({...state, adjustments, adjustment: undefined});
        setPage(1, adjustments);
    }

    const mapToPageData = (item: IScheduleAdjustment): IPageData => {
        const overrideConfig = item.scheduleConfig && item.scheduleConfig.length;
        return {
            adjustment: item,
            practice: item.practice?.practice || '',
            start: moment(item.start).toDate(),
            end: moment(item.end).toDate(),
            additionalDays: +item.additionalDays,
            recurrence: item.recurrence && RECURRENCE_OPTIONS.find(f => f.value == item.recurrence)?.text || 'None',
            days: (item.recurrenceDays || ''.length) && item.recurrenceDays.split(',').filter(f => f.length) || [],
            config: overrideConfig
        }
    }

    const filterFunction = (item: IPageData): boolean => {
        return !(filterState.practice && item.practice != filterState.practice)
            && !(filterState.start && item.start < filterState.start)
            && !(filterState.end && item.end > filterState.end);
    }

    const setPage = (page: number, adjustments = state.adjustments) => {
        const start = (page - 1) * PAGE_SIZE;
        const filteredData: IPageData[] = (adjustments || [])
            .map(item => mapToPageData(item))
            .filter(item => filterFunction(item));
        const data = filteredData.sort((a, b) => (a[sortState.value] > b[sortState.value] ? 1 : -1) * (sortState.ascending ? -1 : 1))
            .slice(start, start + PAGE_SIZE);
        setPageState({...pageState, count: filteredData.length || 0, page, data});
    }

    return (
        <Template activeLink="practice_adjustments">
            <h1 className="float-left">Schedule Adjustments</h1>
            <span className="float-right">
                <Form>
                <Form.Group inline>
                    <Form.Field>
                        <Dropdown
                            options={practices.map((practice) => ({value: practice.practice, text: practice.practice}))}
                            selection clearable
                            value={filterState.practice}
                            placeholder='Practice'
                            onChange={(_e, data) => setFilterState({...filterState, practice: data.value as string})}
                        />
                    </Form.Field>
                    <Form.Field>
                        <ReactDatePicker
                            value={filterState.start && moment(filterState.start).toDate() as any || undefined}
                            selected={filterState.start && moment(filterState.start).toDate() as any || undefined}
                            placeholderText="Start Date"
                            onChange={(date) => setFilterState({...filterState, start: date || undefined})}/>
                    </Form.Field>
                    <Form.Field>
                        <Icon name="arrow right"/>
                    </Form.Field>
                    <Form.Field>
                        <ReactDatePicker value={filterState.end && moment(filterState.end).toDate() as any || undefined}
                                         selected={filterState.end && moment(filterState.end).toDate() as any || undefined}
                                         minDate={filterState.start ?? null}
                                         placeholderText="End Date"
                                         onChange={(date) => setFilterState({...filterState, end: date || undefined})}/>
                    </Form.Field>
                    <Form.Field>
                        <Button primary content="Create Ajustment"
                                onClick={() => setStates({...state, adjustment: {}})}/>
                    </Form.Field>
                </Form.Group>
            </Form>

            </span>

            <br/>
            {adjustment && <SaveModal practices={practices} adjustment={adjustment} close={() => loadData()}/>}
            <Table compact striped size="small">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell/>
                        {HEADERS.map(item => <Table.HeaderCell
                            className="cursorPointer"
                            onClick={() => setSortState({value: item.value, ascending: !sortState.ascending})}
                            icon={sortState.value == item.value ? `sort content ${sortState.ascending ? 'ascending' : 'descending'}` : undefined}
                            content={item.title}/>)}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        (pageState.data || []).map((item) => {
                            return <Table.Row>
                                <Table.Cell>
                                    <Button icon="edit" secondary size="small"
                                            onClick={() => setStates({...state, adjustment: {...item.adjustment}})}/>
                                </Table.Cell>
                                <Table.Cell content={item.practice}/>
                                <Table.Cell content={moment(item.start).format('DD MMMM yyyy')}/>
                                <Table.Cell content={moment(item.end).format('DD MMMM yyyy')}/>
                                <Table.Cell content={item.additionalDays}/>
                                <Table.Cell
                                    content={item.recurrence}/>
                                <Table.Cell
                                    content={item.days.length ? item.days.map(l => <Label content={l}/>) : '-'}/>
                                <Table.Cell
                                    content={item.config ? <Icon name="check circle" color="green"/> : '-'}/>
                            </Table.Row>
                        })
                    }
                </Table.Body>
            </Table>
            <Pagination activePage={pageState.page} onPageChange={(e, d) => setPage(d.activePage as number)}
                        totalPages={Math.ceil(pageState.count / PAGE_SIZE)}/>
        </Template>
    );

}
export default PracticeAdjustment;
