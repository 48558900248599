import * as React from 'react';
import  Template  from './Template';
import UsageTable from './UsageTable'





  const Usage:React.FC<any>=(props)=> {

    return (
      <Template activeLink="usage">
          <UsageTable/>  
      </Template>
    );

}
export default Usage;