import * as React from 'react';
import {useEffect, useState} from 'react';
import {Button, Card, Checkbox, Dropdown, Form, Grid, Modal} from 'semantic-ui-react';
import {useActions} from "../../redux/actions";
import * as adjustmentActions from "../../redux/actions/scheduleAdjustments";
import ReactDatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import {DR_PRACTICE_OPTIONS, OPEN_WEEKS_ARRAY, PRACTICE_TIMES, RECURRENCE_OPTIONS} from "../../constants";
import moment from "moment";
import {IScheduleAdjustment} from "../../model/ScheduleAdjustment";

interface IProps {
    practices: any[],
    adjustment: IScheduleAdjustment;
    close: Function;
}

const SaveModal: React.FC<IProps> = ({practices, adjustment, close}: IProps) => {
    const tryParse = (data: string | undefined) => {
        try{
            return data && JSON.parse(data) || undefined;
        }catch (e) {
            return undefined;
        }
    }
    const [formState, setFormState] = useState({...adjustment, scheduleConfig: tryParse(adjustment.scheduleConfig)});
    const adjustmentAction = useActions(adjustmentActions);

    useEffect(() => {
        !adjustment?.scheduleConfig && setTimeout(() => loadDefaultConfig(true), 0);
    }, [adjustment])

    const openDaysChange = (name: string, value: any) => {
        let obj = {...formState};
        obj.scheduleConfig[name] = value;
        setFormState({...formState, ...obj});
    }

    const loadDefaultConfig = (isEnabled?: boolean) => {
        let scheduleConfig = undefined as any;
        if (isEnabled) {
            const practice = practices.find(f => f.id === formState.practiceId);
            const openDayDetails = practice?.udaPracticeInfo?.openDaysDetails;
            scheduleConfig = openDayDetails ? JSON.parse(openDayDetails) : undefined;
            if (scheduleConfig) {
                [...moment.weekdays().map(a => a.toLowerCase()), ...moment.weekdays().map(a => `${a.toLowerCase()}End`)].forEach(weekDay => {
                    scheduleConfig[weekDay] = practice.udaPracticeInfo[weekDay];
                })
                scheduleConfig.drPractice = practice?.udaPracticeInfo.drPractice;
            }
        }
        setFormState({...formState, scheduleConfig})
    }

    const changeWeekDaysOption = (e, day, option = 'WeekOption', custom = false) => {
        const tempState = {...formState};
        if (!tempState.scheduleConfig['weekDays'])
            tempState.scheduleConfig['weekDays'] = {[day]: {[option]: ''}}
        if (!tempState.scheduleConfig['weekDays'][day])
            tempState.scheduleConfig['weekDays'][day] = {[option]: ''}

        if (custom) {
            tempState.scheduleConfig['weekDays'][day][option] = {'custom': e.value};
        } else {
            if (e.value.includes('custom')) {
                tempState.scheduleConfig['weekDays'][day][option] = {'custom': []};
            } else {
                tempState.scheduleConfig['weekDays'][day][option] = e.value;
            }
        }
        setFormState({...tempState});
    }

    const save = async () => {
        await adjustmentAction.save(formState);
        close();
    }

    const deleteAdjustment = async () => {
        await adjustmentAction.save({...formState, isDeleted: true});
        close();
    }

    const getOpenDaysDetails = (weekDay: string) => {
        const weekDayConfig = formState.scheduleConfig && formState.scheduleConfig['weekDays'] && formState.scheduleConfig['weekDays'][weekDay] || {}
        return <div key={weekDay + 'form'}>
            <Form.Group widths="equal">
                <Form.Field>
                    <Dropdown
                        fluid
                        selection
                        value={(formState.scheduleConfig[weekDay] || "0") + ''}
                        options={PRACTICE_TIMES}
                        onChange={(e, a) => openDaysChange(weekDay, a.value)}
                    />
                </Form.Field>
                <Form.Field>
                    <Dropdown
                        fluid
                        selection
                        value={(formState.scheduleConfig[`${weekDay}End`] || "0") + ''}
                        options={PRACTICE_TIMES}
                        onChange={(e, a) => openDaysChange(`${weekDay}End`, a.value)}
                    />
                </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
                <Form.Field>
                    <label className="labelStyle mt-3" key={'label' + weekDay}>Open Weeks: </label>
                    <Dropdown
                        fluid
                        search selection
                        options={OPEN_WEEKS_ARRAY}
                        value={weekDayConfig['WeekOption'] && weekDayConfig['WeekOption']['custom'] ? 'custom' : weekDayConfig['WeekOption'] || 'all'}
                        onChange={(e, a) => changeWeekDaysOption(a, weekDay)}
                    />
                </Form.Field>
                <Form.Field>
                    <span key={'dayOption' + weekDay}>Week #: </span>
                    <Dropdown
                        search multiple selection
                        id={weekDay}
                        disabled={!(weekDayConfig.hasOwnProperty('WeekOption') && weekDayConfig['WeekOption'].hasOwnProperty('custom'))}
                        options={[...new Array(53)].map((v, i) => ({value: `${i + 1}`, text: i + 1}))}
                        value={weekDayConfig && weekDayConfig['WeekOption'] && weekDayConfig['WeekOption']['custom']}
                        onChange={(e, a) => changeWeekDaysOption(a, weekDay, 'WeekOption', true)}
                    />
                </Form.Field>
            </Form.Group>
            <Form.Group>
                <Form.Field>
                    <label className="labelStyle mt-3" key={'dr_practice' + weekDay}>Dr Practice :</label>
                    <Dropdown search className='form-control'
                              onChange={(e, a) => changeWeekDaysOption(a, weekDay, 'drPractice')}
                              placeholder='Select Dr Practice'
                              selection
                              value={weekDayConfig.drPractice || `${formState.scheduleConfig.drPractice}`}
                              options={DR_PRACTICE_OPTIONS}/>
                </Form.Field>
            </Form.Group>
        </div>
    }


    return <Modal open={true} size="large" centered={true} onClose={() => close()}>
        <Modal.Content style={{minHeight: '65vh'}} scrolling={true}>
            <h2>Save Adjustment</h2>
            <br/>
            <Form>
                <Form.Group widths="equal">
                    <Form.Field>
                        <label>Practice</label>
                        <Dropdown
                            options={practices.map((practice) => ({value: practice.id, text: practice.practice}))}
                            selection
                            value={formState.practiceId}
                            placeholder='Practice'
                            onChange={(_e, data) => {
                                setFormState({...formState, practiceId: data.value as number});
                                !!formState.scheduleConfig && setTimeout(() => loadDefaultConfig(true), 500)
                            }}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Start Date</label>
                        <ReactDatePicker value={formState.start && moment(formState.start).toDate() as any || undefined}
                                         selected={formState.start && moment(formState.start).toDate() as any || undefined}
                                         onChange={(date) => setFormState({...formState, start: date})}/>
                    </Form.Field>
                    <Form.Field>
                        <label>End Date</label>
                        <ReactDatePicker value={formState.end && moment(formState.end).toDate() as any || undefined}
                                         selected={formState.end && moment(formState.end).toDate() as any || undefined}
                                         minDate={formState.start || null}
                                         onChange={(date) => setFormState({...formState, end: date})}/>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field>
                        <label>Additional Dr Days</label>
                        <Form.Input type="number" value={formState.additionalDays}
                                    onChange={(e, data) => setFormState({...formState, additionalDays: data.value})}/>
                    </Form.Field>
                    <Form.Field>
                        <label>Recurrence</label>
                        <Dropdown
                            options={RECURRENCE_OPTIONS}
                            value={formState.recurrence}
                            selection
                            placeholder='Recurrence'
                            onChange={(_e, data) => setFormState({...formState, recurrence: data.value as string})}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Select Days</label>
                        <Dropdown
                            options={moment.weekdays().map(day => ({text: day, value: day}))}
                            value={(formState.recurrenceDays || '').split(',')}
                            selection multiple
                            disabled={!formState.recurrence}
                            placeholder='Recurrence'
                            onChange={(_e, data) => setFormState({
                                ...formState,
                                recurrenceDays: (data.value as string[] || []).join(',')
                            })}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field>
                        <label>Adjust Default Schedule</label>
                        <Checkbox
                            toggle={true}
                            disabled={!formState.practiceId}
                            checked={!!formState.scheduleConfig} onChange={(e, d) => loadDefaultConfig(d.checked)}/>
                    </Form.Field>
                </Form.Group>
                <Grid>
                    <Grid.Row>
                        {formState.scheduleConfig && moment.weekdays().map(w => w.toLowerCase()).map((day, i) =>
                            <Grid.Column width={5}>
                                <Card className="p-1">
                                    <h5>{day.toUpperCase()}</h5>
                                    {getOpenDaysDetails(day)}
                                </Card>
                                <br/>
                            </Grid.Column>)}
                    </Grid.Row>
                </Grid>
            </Form>
        </Modal.Content>
        <Modal.Actions>
            <Button content="Save Adjustment" disabled={['practiceId', 'start', 'end'].some(v => !formState[v])} primary
                    onClick={() => save()}/>

            <Button content="Delete Adjustment" disabled={!formState.id} color="red"
                    onClick={() => deleteAdjustment()}/>
            <Button content="Cancel" secondary onClick={() => close()}/>
        </Modal.Actions>
    </Modal>;

}
export default SaveModal;
