import React from "react"
import {Redirect, Route} from "react-router"
import {PrivateRoute, PrivateRouteProps} from "./components/PrivateRoute"
import Home from "./container/Home"
import SignIn from "./components/SignIn"
import Test from "./components/Test"
import Usage from "./components/Usage"
import ProviderDays from "./components/ProviderDays";
import TimeOffRequests from "./components/TimeOffRequests";
import TimeOffRequestReport from "./components/TimeOffRequestReport";
import {defaultHomePage} from "./constants"
import AvailabilityTracking from "./components/AvailabilityTracking";
import FillRate from "./components/FillRate";
import Login from "./components/Login"
import PracticeAdjustment from "./components/PracticeAdjustment";

const AppRoutes: React.FC<any> = (props) => {
    const isAuthenticated = !!(props.auth && props.auth.user && props.auth.user.id);
    const defaultProtectedRouteProps: PrivateRouteProps = {
        isAuthenticated: isAuthenticated,
        authenticationPath: "/"
    };
    const RedirectTo = (props: any) => isAuthenticated ? <Redirect to={`/${defaultHomePage}`}/> : <SignIn {...props} />
    return (
        <>
            <Route
                exact={true}
                path="/"
                render={props => RedirectTo(props)}
            />
            <Route
                exact={true}
                path="/cdpLogin"
                component={Home}
            />
            <Route
                exact={true}
                path="/login"
                component={Login}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                exact={true}
                path="/dashboard"
                component={Test}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                exact={true}
                path="/time-off-requests"
                component={TimeOffRequests}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                exact={true}
                path="/provider-days"
                component={ProviderDays}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                exact={true}
                path="/tracking"
                component={AvailabilityTracking}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                exact={true}
                path="/fill_rate"
                component={FillRate}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                exact={true}
                path="/practice_adjustments"
                component={PracticeAdjustment}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                exact={true}
                path="/usage"
                component={Usage}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                exact={true}
                path="/timeoff-request-report"
                component={TimeOffRequestReport}
            />


        </>
    )
}
export default AppRoutes
