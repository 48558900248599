import * as React from 'react';
import {useState, useEffect, useRef} from 'react';
import {
    Button,
    Dropdown,
    Form,
    Grid,
    Icon,
    Input,
    Label,
    Table
} from 'semantic-ui-react';
import Template from '../Template';
import {useActions} from "../../redux/actions";
import * as practiceActions from "../../redux/actions/practices";
import * as availabilityTrackingActions from "../../redux/actions/availabilityTracking";
import moment from 'moment';
import HelpMark from "../HelpMark";
import { udrPermissions, PROVIDER_FILL_HEADERS } from "../../constants";
import { CSVLink } from 'react-csv';

const FillRate: React.FC<any> = () => {
    const initialState = {
        practiceOptions: [] as { text: string, value: number, key: any }[],
        currentMonth: new Date(),
        brands: [] as any[],
        fillRateData: [] as any[]
    };

    const [state, setStates] = useState(initialState);
    const [searchValue, setSearch] = useState('');
    const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
    const [udaRole, setUdaRole] = useState([2,10]);
    const [sortBy, setSortBy] = useState({});
    const practiceAction = useActions(practiceActions);
    const availabilityTrackingAction = useActions(availabilityTrackingActions);
    useEffect(() => {
        loadOptions()
    }, []);
    useEffect(() => {
        loadDates(currentMonth)
    }, [udaRole]);

    const loadDates = async (currentMonth = new Date()) => {
        setStates((prevState) => ({...prevState, currentMonth}));
        const months = [-1, 0, 1]
            .map(i => moment(currentMonth).add(i, 'month').format('DD-MMM-YYYY'))
            .join(',')
        const udaOptions:any={}
        udaRole.forEach((val:any)=>udaOptions[val]=val)
        const fillRateData = await availabilityTrackingAction.getFillRate({months, udaRole:JSON.stringify({data:udaOptions})}) || [];
        setStates((prevState) => ({...prevState, fillRateData}))
    }

    const addMonth = async (month: number) => {
        const date = moment(currentMonth).add(month, 'M').toDate();
        await loadDates(date);
    }

    const loadOptions = async () => {
        await Promise.all([loadDates(), getBrands()]);
    }

    const getBrands = async () => {
        const brands: any = await practiceAction.getBrands();
        setStates((prevState) => ({...prevState, brands}))
        return brands.unshift('All')
    }

    const applyFilter = ({practice}: any): boolean => {
        const brandNotSelected = !selectedBrands.length || selectedBrands.includes("All");
        const brandResult = brandNotSelected || selectedBrands.includes(practice && practice.udaPracticeInfo && practice.udaPracticeInfo.brand || '');
        const practiceResult = practice && (practice.practice || '').toLowerCase().includes((searchValue || '').toLowerCase());
        return brandResult && practiceResult;
    }

    const {currentMonth, brands, fillRateData} = state;
    const brandOptions = (brands || []).map((brand, key) => ({value: brand, text: brand, key: `brand_${key}`}));

    const generateTotals = () => {
        const result = fillRateData.filter(applyFilter);
        const totals = [0, 1, 2].map(m => {
            return result.reduce((acc, {data}) => {
                const {expected, workable, completed, scheduled} = data[m];
                acc[0] += expected || 0;
                acc[1] += workable || 0;
                acc[2] += completed || 0;
                acc[3] += scheduled || 0;
                return acc;
            }, [0, 0, 0, 0, 0]);
        });
        return <Table.Row>
            <Table.HeaderCell/>
            {
                Object.values(totals).map((obj) => {
                    const [expectedTotal, workableTotal, completedTotal, scheduledTotal] = obj as any[];
                    const ratio = (((completedTotal + scheduledTotal) / workableTotal) * 100) || 0;
                    const completed = <b>{completedTotal + scheduledTotal}
                        <span className="text-grey ml20">({completedTotal} + {scheduledTotal})</span></b>;
                    return [
                        <Table.HeaderCell content={<b>{expectedTotal}</b>}/>,
                        <Table.HeaderCell content={<b>{workableTotal}</b>}/>,
                        <Table.HeaderCell content={completed}/>,
                        <Table.HeaderCell content={<Label content={ratio.toFixed(2) + '%'} color={getColor(ratio)}/>}/>
                    ];
                })
            }
        </Table.Row>;
    }

    const getColor = (amount: number) => {
        if (amount == 0) return 'red';
        if (amount < 50) return 'orange';
        return amount > 100 ? 'purple' : 'green';
    }

    const sortData = (key: string, i: number) => {
        const getValue = (data) => {
            const {workable, completed, scheduled} = data;
            if (key == 'total')
                return (scheduled || 0) + (completed || 0);
            if (key == 'ratio')
                return (((completed + scheduled) / workable) * 100) || 0;
            return data[key]
        }
        const compareFn = (obj1, obj2) => {
            const multiplier = sortBy[`${key}${i}`] == 'down' ? -1 : 1;
            return multiplier * (getValue(obj1.data[i]) - getValue(obj2.data[i]));
        };

        setSortBy({[`${key}${i}`]: sortBy[`${key}${i}`] == 'down' ? 'up' : 'down'});
        setStates((prevState) => ({...prevState, fillRateData: [...fillRateData].sort(compareFn)}))
    }

    const exportTableToCSV = () => {
        // Get the CSV string for the table
        const dataTable =  document.getElementById("dataTable");
        const csvString = convertTableToCSV(dataTable);
        const fileNameSuffix = [-1, 0, 1].map(i => moment(currentMonth).add(i, 'month'))
            .map(month => moment(month).format('MMMM-YYYY')).join('_');

        // Create a Blob object from the CSV string
        const blob = new Blob([csvString], { type: "text/csv" });

        // Create a new anchor element and set its href attribute to the Blob object
        const anchor = document.createElement("a");
        anchor.href = window.URL.createObjectURL(blob);
        anchor.download = `fill_rate_${fileNameSuffix}.csv`;

        // Click the anchor element to download the CSV file
        anchor.click();
    }
    

    const convertTableToCSV = (table) => {
        // Get all the rows in the table
        const rows = table.querySelectorAll("tr");
      
        // Create a CSV string
        let csvString = "";
      
        // Iterate over the rows and add them to the CSV string
        for (let i = 0; i < rows.length; i++) {
            const row = rows[i];
            const cells = row.querySelectorAll("td");
            if (cells.length == 0) {
                // check if we have headers in the row instead of td
                const headersCells = row.querySelectorAll("th");
                // Add the cell values to the CSV string, separated by commas
                for (let i = 0; i < headersCells.length; i++) {
                    const cell = headersCells[i];

                    // If the cell has a colspan attribute, add the value of the colspan attribute to the CSV string
                    if (cell.colSpan > 1) {
                        csvString += cell.textContent + ",";
                        for (let k = 0; k < cell.colSpan - 1; k++) {
                            csvString += ",";
                        }
                    } else {
                        csvString += cell.textContent + ",";
                    }
                }

            }
            // Add the cell values to the CSV string, separated by commas
            for (let j = 0; j < cells.length; j++) {
                csvString += cells[j].textContent + ",";
            }

        // Add a newline character at the end of each row
          csvString += "\n";
        }
      
        return csvString;
      }

    return (
        <Template activeLink="fill_rate">
            <Grid className="p-1 pb-0">
                <Grid.Row>
                    <Grid.Column width={6}>
                        <h1 className="float-left pl-2">Fill Rate <HelpMark pageId='1'/></h1>
                    </Grid.Column>
                    <Grid.Column width={10}>
                        <Form>
                            <Form.Group className='mb0 float-right'>
                                <Form.Field>
                                    <Button primary icon labelPosition='right'  onClick={() => exportTableToCSV()}>
                                        Export
                                        <Icon name="arrow alternate circle up" className="mr10" />
                                    </Button>
                                </Form.Field>
                                <Form.Field>
                                    <Button.Group>
                                        <Button primary={true} icon='left chevron'
                                                onClick={() => addMonth(-1)}/>
                                        <Button primary={true} icon='right chevron'
                                                onClick={() => addMonth(1)}/>
                                    </Button.Group>
                                </Form.Field>
                                <Form.Field>
                                    <Input placeholder='Search Practice..' onChange={(e, v) => setSearch(v.value)}/>
                                </Form.Field>
                                <Form.Field>
                                    <Dropdown
                                        options={brandOptions}
                                        selection
                                        multiple
                                        style={{zIndex: 901}}
                                        placeholder='Brands'
                                        onChange={(e, data) => setSelectedBrands((data.value as any))}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Dropdown
                                        options={udrPermissions}
                                        selection
                                        style={{zIndex: 901}}
                                        value={udaRole}
                                        multiple
                                        placeholder='UDA Role'
                                        onChange={(_e, data) => {
                                            setUdaRole(data.value as any)
                                        }}
                                    />
                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <div className='fixed-table-header'>
            <Table  striped={true} size="small" compact={true} celled={true} id="dataTable">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell/>
                        {
                            [-1, 0, 1]
                                .map(i => moment(currentMonth).add(i, 'month'))
                                .map(month => <Table.HeaderCell colSpan={4} content={<h3
                                    className="text-center">{moment(month).format('MMMM YYYY')}</h3>}/>)
                        }
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell content={"Practices"}/>
                        {
                            [-1, 0, 1]
                                .map(i => moment(currentMonth).add(i, 'month'))
                                .map((date, i) =>
                                    PROVIDER_FILL_HEADERS.map(({name, key}) => <Table.HeaderCell colSpan={1}>
                                        {name}
                                        <Icon
                                            color={sortBy[`${key}${i}`] ? undefined : 'grey'}
                                            name={`sort amount ${sortBy[key + i] || 'down'}` as any}
                                            className="cursorPointer" onClick={() => sortData(key, i)}
                                        />
                                    </Table.HeaderCell>))
                        }
                    </Table.Row>
                    {generateTotals()}
                </Table.Header>
                <Table.Body>
                    {
                        fillRateData.filter(applyFilter).map(({practice, data}) => <Table.Row>
                            <Table.Cell>{practice.practice}</Table.Cell>
                            {
                                (data || []).map(({expected, workable, completed, scheduled}) => {
                                    const ratio = (((completed + scheduled) / workable) * 100) || 0;
                                    return [
                                        <Table.Cell colSpan={1}
                                                    content={<Label basic={true} content={expected} color="grey"/>}/>,
                                        <Table.Cell colSpan={1}
                                                    content={<Label basic={true} content={workable} color="blue"/>}/>,
                                        <Table.Cell colSpan={1}
                                                    content={<Label.Group>
                                                        {completed && <Label basic={true} content={completed}
                                                                             color="green" icon="check"/> || ''}
                                                        {scheduled && <Label basic={true} content={scheduled}
                                                                             color="grey"
                                                                             icon="calendar alternate outline"/>
                                                            || ''}
                                                    </Label.Group>}/>,
                                        <Table.Cell colSpan={1}
                                                    content={<Label content={ratio.toFixed(2) + '%'}
                                                                    color={getColor(ratio)}/>}/>
                                    ]
                                })
                            }
                        </Table.Row>)
                    }
                </Table.Body>
            </Table>
            </div>
        </Template>
    );

}
export default FillRate;
