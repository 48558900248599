import * as React from 'react';
import {useEffect, useState} from 'react';
import {Button, Input, Pagination, Table} from "semantic-ui-react";
import * as UsageActions from "../redux/actions/usage";
import {allowDeleteUserId, udrPermissions} from '../constants';
import _ from "lodash";
import ErrorModal from './ErrorModal';
import {Loading} from './Loading'
import {useActions} from "../redux/actions";
import HelpMark from "./HelpMark";


const maxRows = 15;

interface UsageReportState {
    loading: boolean,
    loadErr: any,
    userId: string,
    role: number,
    column: string,
    oldData: any,
    data: any,
    activePage:number,
    order: boolean,
    showHidden: boolean,
    searchKeyword:string,
    allowDelete: any,
}

const UsageTable:React.FC<any>=(props)=> {

    const initialState: UsageReportState = {
        loading: false,
        loadErr: null,
        userId: '',
        role: 2,
        column: '',
        oldData: [],
        data: null,
        activePage:1,
        searchKeyword:'',
        order: true,
        showHidden: false,
        allowDelete: (props.auth && allowDeleteUserId.indexOf(props.auth.id) >= 0),
    }
    const [state, setState] = useState<UsageReportState>(initialState);
    const [activePage,setActivePage] = useState(1)
    const actions = useActions(UsageActions);

    useEffect(() => {
        setState({...state,loading: true})
        fetchUsageData(null, null);
    },[])

    const fetchUsageData = (id: any, role: any) => {
        role = udrPermissions[role] ? udrPermissions[role].text : 'All';
        (actions.getUsageData(id, role) as any)
            .then((res: any) => {
                let tableData = setTableData(res);
                tableData = _.sortBy(tableData, ["date"]).reverse();
                setState({...state,oldData: tableData, data: tableData, loading: false});
            })
            .catch((err: any) => {
                console.log('Found error on get billingData', err);
                setState({...state,loadErr: err})
            });
    }

    const setTableData = (data: any) => {
        let dataArray = new Array();
        if (Object.keys(data).length) {
            Object.keys(data).forEach((key) => {
                const item = data[key];
                let flag = true;
                if (state.userId && state.userId !== 'All' && item.value !== state.userId) {
                    flag = false;
                }
                if (flag && item.text !== 'All') {
                    dataArray.push({
                        firstName: item.firstName,
                        lastName: item.lastName,
                        userId: item.userId,
                        hideUsage: item.hideUsage,
                        date: item.Date,
                        total: item.total,
                        Cell: item.Cell,
                        Desktop: item.Desktop,
                        deviceOS: item.deviceOS
                    });
                }
            });
        }
        return dataArray;
    }

    const search = (e: any) => {
        const keyword = e.target.value.toLowerCase();
        let data = state.oldData;
        if (keyword && keyword.length) {
            data = data.filter(function (element: any) {
                return (element.firstName.toLowerCase().indexOf(keyword) >= 0 || element.lastName.toLowerCase().indexOf(keyword) >= 0) ? true : false;
            });
        }
        let sortData = _.sortBy(data, [state.column]);
        if (!state.order)
            sortData = sortData.reverse();
        setState({...state,searchKeyword: keyword, data: sortData});
    }

    const handleSort = (clickedColumn: any) => () => {
        const {column, data, order} = state;
        if (column !== clickedColumn) {
            setState({
                ...state,
                column: clickedColumn,
                data: _.sortBy(data, [clickedColumn]),
                order: true,
            })

            return
        }

        setState({
            ...state,
            data: data.reverse(),
            order: !order
        })
    }

    const onchangeUser = (e: any, {value}: any) => {
        const {role} = state;
        setState({...state,userId: value});
        fetchUsageData(value, role);
    };

    const flushData = () => {
        const {actions} = props;
       // if (confirm("Are you sure you want to clear all usage data? Action will not undo.")) {
            const {userId, role} = state;
            (actions.flushUsageData({userId, role}) as any)
                .then((res: any) => {
                    if (res) fetchUsageData(userId, role);
                });
       // }
    };

    const hideErrorModal = () => {
        props.actions.hideErrorModal();
    }

    const onChangeHide = (e: any, id: any) => {
        let obj = {
            hideUsage: (e.target.value === 'true'),
            userId: id
        };

        (props.actions.hideUsageReport(obj) as any)
            .then((res: any) => {
                if (res) fetchUsageData('', '');
            });
    }

    const showHiddenReports = (e: any) => {
        setState({...state,showHidden: (e.target.value === 'true')});
    }

    const getPaginated = () => {
        const { data,activePage } = state;
        const start = (activePage - 1) * maxRows;
        const end = start + maxRows;
        return (data || []).slice(start, end)
    };

    const {data, column, order, allowDelete, showHidden, loading, loadErr} = state;
    const direction = order ? 'down' : 'up';


    return (
        <div className="calendar-container">
            {(loadErr) && <ErrorModal hideDetailsModal={hideErrorModal} errorMessage={loadErr}/>}
            <div className="row mt10">
                <div className="col-12">
                    <h2 className="float-left">Usage<HelpMark pageId='1'/></h2>

                    <div className="float-right topDropdown">
                        {allowDelete &&
                        <span>Show Hidden Reports <Input className='mt10 mr10' type="checkbox" name="showHidden"
                                                         defaultChecked={(showHidden)}
                                                         onChange={(e) => showHiddenReports(e)}
                                                         defaultValue={(!showHidden)}/></span>}
                        {allowDelete && <Button primary onClick={flushData}>Flush Data</Button>}
                        <Input className='mb10' icon='search' placeholder="Search" onChange={search}/>
                        {loading && <Loading/>}
                    </div>
                </div>
            </div>
            <div className="ui card">
                <div className="content">
                    <div className="table-responsive">
                        <Table className="table table-striped table-hover">
                            <Table.Header>
                                <Table.Row>
                                    {allowDelete && <Table.HeaderCell>
                                        <div>Hide</div>
                                    </Table.HeaderCell>}
                                    <Table.HeaderCell
                                        className={`cursorPointer ${column === 'lastName' ? 'active' : ''}`}
                                        onClick={handleSort('lastName')}>
                                        <div>
                                            NAME
                                            <i className={`sort amount ${direction} icon ml-05`}></i>
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className={`cursorPointer ${column === 'date' ? 'active' : ''}`}
                                                      onClick={handleSort('date')}>
                                        LAST LOGIN DATE
                                        <i className={`sort amount ${direction} icon ml-05`}></i>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className={`cursorPointer`}>TOTAL ACCESS</Table.HeaderCell>
                                    <Table.HeaderCell className={`cursorPointer`}>MOBILE COUNT</Table.HeaderCell>
                                    <Table.HeaderCell className={`cursorPointer`}>DESKTOP COUNT</Table.HeaderCell>
                                    <Table.HeaderCell
                                        className={`cursorPointer ${column === 'deviceOS' ? 'active' : ''}`}
                                        onClick={handleSort('deviceOS')}>
                                        <div>
                                            RECENT MOBILE OS
                                            <i className={`sort amount ${direction} icon ml-05`}></i>
                                        </div>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            {(data && getPaginated().length) ?
                                <Table.Body>
                                    {getPaginated().map((item: any, key: any) => {
                                            if (item.hideUsage && !showHidden) {
                                                return true;
                                            }
                                            return (<Table.Row key={key}>
                                                {allowDelete &&
                                                <Table.Cell><Input key={item.userId} className='mt10 ml20' type="checkbox"
                                                                   name={`hideUsage[${item.userId}]`}
                                                                   defaultChecked={(item.hideUsage)}
                                                                   onChange={(e) => onChangeHide(e, item.userId)}
                                                                   defaultValue={(!item.hideUsage)}/></Table.Cell>}
                                                <Table.Cell>{item.lastName} {', '} {item.firstName}</Table.Cell>
                                                <Table.Cell>{item.date}</Table.Cell>
                                                <Table.Cell>{item.total}</Table.Cell>
                                                <Table.Cell>{item.Cell}</Table.Cell>
                                                <Table.Cell>{item.Desktop}</Table.Cell>
                                                <Table.Cell>{item.deviceOS}</Table.Cell>
                                            </Table.Row>)
                                        }
                                    )}
                                </Table.Body>
                                :
                                <Table.Body><Table.Row><Table.HeaderCell colSpan='100%'>No record
                                    found</Table.HeaderCell></Table.Row></Table.Body>
                            }
                        </Table>

                        <Pagination onPageChange={(_e, data) => setState({...state,activePage:data.activePage as number})}
                                    defaultActivePage={initialState.activePage} 
                                    totalPages={Math.ceil(data && data.length / maxRows)} />
                    </div>
                </div>
            </div>
        </div>
    );

}

export default UsageTable;
