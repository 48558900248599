import { Action, ActionType } from "../../model/actionType";
import {DoctorDays } from "../../model/dentistDays";
import createReducer from "./createReducer";
const initialState:any = [];

export const dentistDays = createReducer(initialState, {
  [ActionType.LOAD_DOCTOR_DAYS](state: DoctorDays, action: Action<DoctorDays>) {
    return Object.assign({ ...state }, action.payload);
  }
});
