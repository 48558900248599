interface MenuItem {
    name: string;
    icon: any;
    label: string;
    title: string;
    subMenus: Array<MenuItem>;
    role: Array<string>;
    hide?: boolean;
    customIcon?: string;
}

export const MENUS: Array<MenuItem> = [
    {
        name: "dashboard",
        icon: "sliders vertical large",
        label: "DASHBOARD",
        title: "DASHBOARD",
        subMenus: [],
        role: [],
    },
    {
        name: "Time Off Requests",
        icon: "clock outline icon",
        label: "Time Off Requests",
        title: "Time Off Requests",
        subMenus: [],
        role: [],
    },
    {
        name: "provider days",
        icon: "calendar",
        label: "Provider Days",
        title: "Provider Days",
        subMenus: [],
        role: [],
    },
    {
        name: "tracking",
        icon: "tasks",
        label: "Availability Tracking",
        title: "Availability Tracking",
        subMenus: [],
        role: [],
    },
    {
        name: "fill_rate",
        icon: "chart line",
        label: "Fill Rate",
        title: "Fill Rate",
        subMenus: [],
        role: [],
    },
    {
        name: "practice_adjustments",
        icon: "cog",
        label: "Schedule Adjustments",
        title: "Schedule Adjustments",
        subMenus: [],
        role: [],
    },
    {
        name: "Usage",
        icon: "pie chart",
        label: "Usage",
        title: "Usage",
        subMenus: [],
        role: [],
    },
    {
        name: "TimeOff Request Report",
        icon: "clock outline icon",
        label: "TimeOff Request Report",
        title: "TimeOff Request Report",
        subMenus: [],
        role: [],
    },
];
