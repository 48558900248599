import {ActionType} from "../../model/actionType";
import {errorHandler} from "./auth";

export const get = (params: any) => async (dispatch: Function, getState: Function, api: any) => {
    const query = '?' + Object.keys(params || {}).map(key => `${key}=${params[key]}`).join('&');
    const url = `schedule_adjustments${query}`;
    dispatch({type: ActionType.LOADER, payload: true})
    try {
        const resp = await api.get(url);
        dispatch({type: ActionType.LOADER, payload: false})
        return resp;

    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false})
        dispatch(errorHandler(err))
        return [];
    }
}

export const save = (payload: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    const scheduleConfig = payload.scheduleConfig ? JSON.stringify(payload.scheduleConfig) : undefined;
    try {
        const resp = await api.post('schedule_adjustments', {...payload, scheduleConfig});
        dispatch({type: ActionType.LOADER, payload: false})
        return resp;

    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false})
        dispatch(errorHandler(err))
        return undefined
    }
}
