import {ActionType} from "../../model/actionType"
import {errorHandler} from "./auth"

export const providerDays = () => async (dispatch:Function,getState:Function,api:any)=>{
    const url = `dentists/providerDays`;
    dispatch({type:ActionType.LOADER,payload:true})
    try {
        const resp = await api.get(url);
        dispatch({ type: ActionType.LOAD_DOCTOR_DAYS, payload: { list:  resp }  });
        dispatch({type:ActionType.LOADER,payload:false})
        return resp;

    } catch(err) {
        dispatch({type:ActionType.LOADER,payload:false})
        return dispatch(errorHandler(err))
    }
}

export const sendProviderDaysViaEmail = (payload) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type:ActionType.LOADER,payload:true})
    try {
        const response = await api.post('dentists/providerDays/sendProviderDaysViaEmail', payload)
        dispatch({type:ActionType.LOADER,payload:false})
        return response;
    } catch (error) {
        // Handle error
        console.log("sendProviderDaysViaEmail::error::", error)
        dispatch({type:ActionType.LOADER,payload:false})
        return dispatch(errorHandler(error))
    }
};



