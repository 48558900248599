import * as React from "react";
import {useState} from "react";
import helpIcon from '../styles/images/help-icon.png';
import HelpPageModal from "./HelpPageModal";

interface Props {
  pageId: string
}

function HelpMark(props: Props) {


  const initialState : any = {
    helpPageModal : false
  }

  const [state, setStates] = useState(initialState);

  const openHelpPage = () => {
    setStates({ helpPageModal: true });
  };

  const hideHelpPageModal = () => {
    setStates({ helpPageModal: false });
  };

  return (
      <div className="helpMark">
        <span onClick={() => openHelpPage()}><img alt="help" className="cursorPointer" src={helpIcon} /></span>
        { state.helpPageModal &&
            <HelpPageModal hideHelpPageModal={hideHelpPageModal} pageId={props.pageId} />
        }
      </div>
  );
}

export default HelpMark;
