import React, {useEffect, useState} from "react"
import {Button, Dropdown, Form, Grid, Icon, Input, Label, Modal, TextArea} from "semantic-ui-react";
import moment from "moment";
import {RouteComponentProps} from "react-router-dom";
import * as TimeOffRequestsActions from "../redux/actions/timeOffRequests";
import {useActions} from "../redux/actions";
const dateOnlyFormat = 'YYYY-MM-DD';

interface Props extends  RouteComponentProps<void> {
  selectedDentist: any,
  hideDentistListForm: any,
  unscheduledDoctors : any,
  timeoffDetails : any,
  availableDentists : any,
}

const DentistListModal:React.FC<any>=(props: Props)=>{
  const initialState:any = {
    timeOffReqs : [],
    loadingData : false,
    order: true,
    column: null,
    odAbbrs: null,
    odUsers: null,
    dentistDistance : []
  }

  const [state, setState] = useState(initialState);

  const timeOffRequestsActions = useActions(TimeOffRequestsActions);

  useEffect(() => {
    fetchAvailableDoctorsTimeOff();
  }, []);


  const fetchAvailableDoctorsTimeOff = () => {
    const { timeoffDetails, availableDentists} = props;
    const obj = {
      startDate : timeoffDetails.startDate,
      endDate : timeoffDetails.endDate,
      availableDentists : availableDentists
    }
    const obj2 = {
      practiceId : timeoffDetails.practiceId && timeoffDetails.practiceId.replace(/@/g,''),
      availableDentists : availableDentists
    }
    const distance =  timeOffRequestsActions.getDentistDistanceFromLocation(obj2)
    const doctorsTimeOff =  timeOffRequestsActions.getAvailableDoctorsTimeOff(obj)
    Promise.all([distance, doctorsTimeOff]).then(values => {
      const dentistDistance = values[0];
      const timeoffRes = values[1];
      const timeOffReq: any = {};
      timeoffRes && timeoffRes.forEach((dataArr:any) => {
        timeOffReq[dataArr.userId] = {};
        dataArr.date = moment(dataArr.startDate).utc().format('MM-DD-YYYY');

        if(dataArr.status === 0){
          dataArr.status = 'Pending';
        } else if(dataArr.status === 1){
          dataArr.status = 'Approved';
        } else if(dataArr.status === 2){
          dataArr.status = 'Denied';
        }

        timeOffReq[dataArr.userId] = dataArr;
      })
      setState({...state, loadingData : false , timeOffReqs : timeOffReq, dentistDistance : dentistDistance });

    })

  }


  const handleSort =  (clickedColumn:any , date:any) => {

  }


  const {selectedDentist, timeoffDetails, unscheduledDoctors} = props;
  const { column, order, loadingData, timeOffReqs, dentistDistance } = state;
  const direction = order ? 'down' : 'up';

  return (
    <>
      <Modal
        open={true}
        onClose={props.hideDentistListForm}
        centered={false}
        style = {{ overflow: 'auto' }}
        closeIcon
        size="large"
      >
        <Modal.Header className="py10">
          List of available Doctors
          {/*<Button secondary className="btnPrint ml15">*/}
          {/*  <ReactToPrint*/}
          {/*    trigger={() => <Icon name='print' className="printIcon"/> }*/}
          {/*    content={() => this.componentRef}*/}
          {/*  />*/}
          {/*</Button>*/}
        </Modal.Header>

        <Modal.Content scrolling className="pb-0">
          {/*<div ref={el => (this.componentRef = el)}>*/}
          <div>
            <Modal.Description>
              {
                <div className="row">
                  <div className="col-12">
                    <div className="ui dentist-view-container">
                      <h4 className="text-center primaryColor">{ ' '+selectedDentist.firstName+' '+selectedDentist.lastName+ ' has requested Timeoff for '+timeoffDetails.practice+' On Days below: '}</h4>
                      <div className="timeoffNotes">
                        <p><b>Reason :-</b> {timeoffDetails.reason}</p>
                        <p><b>Notes :-</b> {timeoffDetails.notes}</p>
                      </div>
                      {Object.keys(unscheduledDoctors).map((date) =>
                        <div key={date}>
                          <h4 className="text-center primaryColor mt10">{ moment(parseInt(date)).utc().format('MM-DD-YYYY')}</h4>
                          <div className="table-responsive">
                            <table className="table table-striped table-hover mb-0 mt-0">
                              <thead>
                              <tr>
                                <th className={`cursorPointer ${column === 'lastName' ? 'active' : ''}`}
                                    onClick={()=> handleSort('lastName', date)}>
                                  <div>
                                    Doctor Name
                                    <i className={`sort amount ${direction} icon ml-05`}></i>
                                  </div>
                                </th>
                                <th className={`cursorPointer ${column === 'homeLocation' ? 'active' : ''}`}
                                    onClick={()=>handleSort('homeLocation', date)}>
                                  <div>
                                    Home Location
                                    <i className={`sort amount ${direction} icon ml-05`}></i>
                                  </div>
                                </th>
                                <th className={`cursorPointer ${column === 'canWork' ? 'active' : ''}`}
                                    onClick={()=>handleSort('canWork', date)}>
                                  <div>
                                    Can Work
                                    <i className={`sort amount ${direction} icon ml-05`}></i>
                                  </div>
                                </th>
                                <th>Timeoff Request</th>
                                <th> Status</th>
                                <th>Distance (in Miles)</th>
                              </tr>
                              </thead>
                              <tbody>
                              {unscheduledDoctors[date].map((doctors: any)=> {

                                  let offDate = false;
                                  if( !loadingData && timeOffReqs && timeOffReqs[doctors.id] && moment(parseInt(date)).utc().format('MM-DD-YYYY') >= moment(timeOffReqs[doctors.id].startDate).utc().format('MM-DD-YYYY') && moment(parseInt(date)).utc().format('MM-DD-YYYY') <= moment(timeOffReqs[doctors.id].endDate).utc().format('MM-DD-YYYY') ){
                                    offDate = true;
                                  }
                                  if(!doctors.status) {
                                    return true;
                                  }
                                  const drName = doctors['firstName']+' '+doctors['lastName'];

                                  return (
                                    <tr key={doctors.id}>
                                      <td >{drName}</td>
                                      <td >{doctors['homeLocation']}</td>
                                      <td >{doctors['canWork']}</td>
                                      <td >{!loadingData && offDate && timeOffReqs && timeOffReqs[doctors.id] && moment(parseInt(date)).utc().format('MM-DD-YYYY') }</td>
                                      <td >{!loadingData && offDate && timeOffReqs && timeOffReqs[doctors.id] &&
                                      timeOffReqs[doctors.id].status  }</td>
                                      <td>{dentistDistance && dentistDistance[doctors.id] && dentistDistance[doctors.id].toFixed(2)}</td>
                                    </tr>
                                  )

                                }
                              )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )
                      }
                    </div>
                  </div>
                </div>

              }

            </Modal.Description>
          </div>
        </Modal.Content>

      </Modal>

    </>
  )
}

export default DentistListModal