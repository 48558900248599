import {ActionType} from "../../model/actionType";
import {errorHandler} from "./auth";

export const getTrackingData = (params:any) => async (dispatch:Function,getState:Function,api:any)=>{
    const query = '?'+ Object.keys(params || {}).map(key => `${key}=${params[key]}`).join('&');
    const url = `availability_tracking${query}`;
    dispatch({type:ActionType.LOADER,payload:true})
    try {
        const resp = await api.get(url);
        dispatch({ type: ActionType.LOAD_OVERRIDE_DAY, payload: resp  });
        dispatch({type:ActionType.LOADER,payload:false})
        return resp;

    } catch(err) {
        dispatch({type:ActionType.LOADER,payload:false})
        dispatch(errorHandler(err))
        return undefined;
    }
}

export const getFillRate = (params:any) => async (dispatch:Function,getState:Function,api:any)=>{
    const query = '?'+ Object.keys(params || {}).map(key => `${key}=${params[key]}`).join('&');
    const url = `availability_tracking/summary${query}`;
    dispatch({type:ActionType.LOADER,payload:true})
    try {
        const resp = await api.get(url);
        dispatch({type:ActionType.LOADER,payload:false})
        return resp;

    } catch(err) {
        dispatch({type:ActionType.LOADER,payload:false})
        dispatch(errorHandler(err))
        return undefined;
    }
}
